<template>
  <div class="customer-form">
    <div class="customer-form-title">
      <h3>{{ $t("CUSTOMERS.CUSTOMER_INFORMATION") }}</h3>
    </div>
    <div class="customer-form-divider"></div>
    <p class="customer-form-radio">
      <base-radio
        v-model="customerType"
        :disabled="disabled"
        :value="INTERNAL"
        :name="INTERNAL"
        inline
      >
        {{ $t("COMMON.INTERNAL") }}
      </base-radio>
      <base-radio
        v-model="customerType"
        :disabled="disabled"
        :name="EXISTING_CUSTOMER"
        :value="EXISTING_CUSTOMER"
        inline
      >
        {{ $t("CUSTOMERS.EXISTING_CUSTOMER") }}
      </base-radio>
      <base-radio
        v-model="customerType"
        :value="NEW_CUSTOMER"
        :name="NEW_CUSTOMER"
        :disabled="disabled"
        inline
      >
        {{ $t("CUSTOMERS.NEW_CUSTOMER") }}
      </base-radio>
    </p>
    <div class="select-customer" v-if="customerType == EXISTING_CUSTOMER">
      <!-- <div class="customer-form-title">
        <h3>{{ $t("CUSTOMERS.ADD_EXISTING_CUSTOMER") }}</h3>
      </div> -->
      <div class="d-flex gap-4 align-items-end justify-content-between">
        <div class="w-100">
          <base-input
            :label="`${$t('CUSTOMERS.SELECT_CUSTOMER')}`"
            :placeholder="$t('CUSTOMERS.SELECT_CUSTOMER')"
          >
            <customer-selector
              :allowNone="false"
              :customer="object.customer?.id"
              :disabled="disabled"
              :filterOrganization="object.organization?.id ?? null"
              :filterable="true"
              :showAll="false"
              @customerChanged="getCustomer"
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.customer" />
        </div>

        <div
          class="inspection-form-group next-button p-0 pb-4 w-auto justify-content-end border-top-0"
          v-if="
            $idExist(object.customer?.id) &&
            $currentUserCan($permissions.PERM_EDIT_CUSTOMERS)
          "
        >
          <base-button
            class="btn btn-sm elite-submit"
            size="sm"
            type="button"
            :disabled="disabled"
            @click="updateCustomer"
          >
            <span class="text-nowrap">
              {{
                showEditCustomerForm
                  ? $t("COMMON.CANCEL")
                  : $t("CUSTOMERS.EDIT_CUSTOMER")
              }}
            </span>
          </base-button>
        </div>
      </div>
      <div
        class="px-3"
        v-if="
          $idExist(object.customer?.id) &&
          showEditCustomerForm &&
          $currentUserCan($permissions.PERM_EDIT_CUSTOMERS)
        "
      >
        <customer-form
          :customerData="object.customer"
          :disable="
            disabled || !$currentUserCan($permissions.PERM_CREATE_CUSTOMERS)
          "
          :formErrors="formErrorsValue"
          :loading="loading"
          @customerSubmitted="handleCustomerFormSubmit"
          @formChanged="customerChanged"
        >
          <template #customer-form-title>
            <span>{{ $t("CUSTOMERS.CUSTOMER_INFORMATION") }}</span>
          </template>
          <template #submit-button><span></span></template>
        </customer-form>
      </div>
    </div>
    <div class="select-customer" v-if="customerType == INTERNAL">
      <!-- <div class="customer-form-title">
        <h3>{{ $t("COMMON.ADD_INTERNAL") }}</h3>
      </div> -->
      <base-input
        :label="`${$t('INSPECTIONS.SELECT_APPROVER')}`"
        :placeholder="$t('INSPECTIONS.SELECT_APPROVER')"
      >
        <user-selector
          :allow-none="false"
          :disabled="disabled"
          :filter-organization="object.organization?.id ?? null"
          :filter-permission="$permissions.PERM_APPROVE_ESTIMATIONS"
          :filterable="true"
          :show-all="false"
          :user="object.approver?.id"
          @userChanged="getApprover"
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.customer" />
    </div>
    <customer-form
      :customerData="object.customer"
      :disable="
        disabled || !$currentUserCan($permissions.PERM_CREATE_CUSTOMERS)
      "
      :formErrors="formErrorsValue"
      :loading="loading"
      @customerSubmitted="handleCustomerFormSubmit"
      @formChanged="customerChanged"
      v-if="customerType == NEW_CUSTOMER"
    >
      <template #customer-form-title><span></span></template>
      <template #submit-button><span></span></template>
    </customer-form>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import defaultCustomer from "../views/Pages/CrmModule/CustomerManagement/defaultCustomer";
import { cloneDeep } from "lodash";
import CustomerSelector from "@/components/CustomerSelector.vue";
import UserSelector from "@/components/UserSelector.vue";
import CustomerForm from "../views/Pages/CrmModule/CustomerManagement/partials/CustomerForm.vue";
import requestErrorMixin from "@/mixins/request-error-mixin";

export default {
  name: "customer-step-form-component",

  components: {
    ValidationError,
    CustomerSelector,
    CustomerForm,
    UserSelector,
  },

  mixins: [formMixin, requestErrorMixin],

  props: {
    objectData: { type: Object },
    formErrors: {
      type: [Object, Array],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    let customer = cloneDeep(this.objectData.customer);
    let approver = cloneDeep(this.objectData.approver);

    if (!customer) {
      customer = {
        id: null,
        type: "customers",
      };
    }
    if (!approver) {
      approver = {
        id: null,
        type: "users",
      };
    }

    customer.organization = this.objectData.organization;
    const object = {
      ...this.objectData,
      customer,
      approver,
    };

    const objectBackup = cloneDeep(object);

    const formErrorsValue = cloneDeep(this.formErrors);
    return {
      object,
      loading: false,
      formErrorsValue,
      objectBackup,
      customer: this.objectData.customer ?? null,
      customerType: "INTERNAL",
      EXISTING_CUSTOMER: "EXISTING_CUSTOMER",
      INTERNAL: "INTERNAL",
      NEW_CUSTOMER: "NEW_CUSTOMER",
      showEditCustomerForm: false,
    };
  },

  computed: {},

  created() {
    this.setCustomerType();
  },

  methods: {
    setCustomerType() {
      if (
        !this.$idExist(this.object.approver.id) &&
        this.$idExist(this.object.customer.id)
      ) {
        this.customerType = this.EXISTING_CUSTOMER;
      } else {
        this.customerType = this.INTERNAL;
      }
    },
    async handleCustomerFormSubmit(customerData) {
      this.loading = true;
      customerData = { ...this.object.customer, ...customerData };
      if (customerData.customer_type === CUSTOMER_TYPE_INDIVIDUAL) {
        customerData.company_name = "N/A";
        customerData.billing_company_name = "N/A";
      } else if (customerData.customer_type === CUSTOMER_TYPE_COMPANY) {
        customerData.firstname = "N/A";
        customerData.lastname = "N/A";
        customerData.billing_firstname = "N/A";
        customerData.billing_lastname = "N/A";
      }

      let customer = null;
      try {
        if (this.$idExist(customerData?.id)) {
          await this.$store.dispatch("customers/update", customerData);
          customer = await this.$store.getters["customers/customer"];
        } else {
          delete customerData.id;
          await this.$store.dispatch("customers/add", customerData);
          customer = await this.$store.getters["customers/customer"];
        }

        this.customerChanged(customer);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrorsValue = error.response?.data?.errors;
      } finally {
        this.loading = false;
      }
    },

    customerChanged(customerData) {
      this.object.customer = {
        ...this.object.customer,
        ...customerData,
      };

      if (this.$idExist(customerData?.id)) {
        this.objectBackup.customer = {
          ...this.objectBackup.customer,
          ...customerData,
        };
      }
    },

    async getCustomer(customerId, customer) {
      this.showEditCustomerForm = false;
      if (!customerId) {
        this.object.customer = {
          id: null,
          ...defaultCustomer,
          organization: {
            ...this.objectData.organization,
          },
        };
        this.customer = null;
      } else {
        this.object.customer = {
          id: customer.id,
          type: customer.type,
        };
        this.customer = {
          ...customer,
          organization: {
            ...this.objectData.organization,
          },
        };
      }
    },

    async getApprover(approverId, approver) {
      if (!approverId) {
        this.object.approver = {
          id: null,
          type: "users",
        };
      } else {
        this.object.approver = {
          ...this.object.approver,
          ...approver,
        };
      }
    },

    updateCustomer() {
      if (this.customer) {
        if (!this.showEditCustomerForm) {
          this.object = { ...this.object, customer: this.customer };
        } else {
          this.object = {
            ...this.object,
            customer: {
              id: this.object.customer.id,
              type: this.object.customer.type,
            },
          };
        }
        this.showEditCustomerForm = !this.showEditCustomerForm;
      } else {
        this.showEditCustomerForm = false;
      }
    },
  },

  watch: {
    object: {
      handler: function () {
        this.$emit("update:objectData", this.object);
      },
      deep: true,
    },
    objectData: {
      handler: function (value) {
        if (_.isEqual(value, this.object)) return;

        this.object = { ...this.object, ...value };
      },
      deep: true,
    },
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
      this.formErrorsValue = cloneDeep(errors);
    },
    customerType() {
      switch (this.customerType) {
        case this.NEW_CUSTOMER:
          let customer = {
            id: null,
            ...cloneDeep(defaultCustomer),
            organization: { ...this.objectBackup.organization },
          };

          this.object = { ...this.object, customer, approver: null };
          break;
        case this.INTERNAL:
          this.object = {
            ...this.object,
            customer: null,
            approver: {
              ...this.objectBackup.approver,
              id: this.objectBackup.approver?.id ?? null,
              type: "users",
            },
          };
          break;
        case this.EXISTING_CUSTOMER:
          this.object = {
            ...this.object,
            approver: null,
            customer: {
              ...this.objectBackup.customer,
              id: this.objectBackup.customer?.id ?? null,
              type: "customers",
            },
          };
          break;
      }
    },
    showEditCustomerForm() {
      if (this.showEditCustomerForm) {
        this.object.customer = {
          ...this.object.customer,
          organization: {
            ...this.objectData.organization,
          },
        };
      } else {
        delete this.object.customer?.organization;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.gap-4 {
  gap: 1rem /* 16px */;
}
</style>
