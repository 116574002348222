var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"elite-tabs-wrapper-content"},[(_vm.viewHeader)?_c('repair-ref-standardized',{attrs:{"show-buttons":_vm.showButtons,"canCancel":_vm.showButtons && _vm.canCancelRepair(_vm.repair),"canEdit":_vm.showButtons && _vm.canEditRepair(_vm.repair),"repair":_vm.repair},on:{"cancel":function($event){_vm.showRepairCancelModel = true},"edit":_vm.editRepair}}):_vm._e(),_c('div',{staticClass:"global-body"},[_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.CODE")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.repair}})],1)]),_c('dl',{staticClass:"width_1-2 status"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.STATUS")))]),_c('dd',[_c('repair-status-badge',{attrs:{"status":_vm.repair.status,"repair":_vm.repair}})],1)]),_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.EXCERPT")))]),_c('dd',{staticClass:"col-sm-8",domProps:{"innerHTML":_vm._s(_vm.repair.excerpt)}})]),(_vm.repair.times_status)?_c('dl',{staticClass:"width_1-2 status"},[_c('dt',[_vm._v(_vm._s(_vm.$t("REPAIRS.TIMES_STATUS")))]),_c('dd',[_c('repair-status-badge',{attrs:{"status":_vm.repair.times_status,"repair":_vm.repair}},[_vm._v(" "+_vm._s(_vm.$tc(`REPAIRS.REPAIR_TYPES_STATUS_${_vm.repair.times_status}`) ? _vm.$t(`REPAIRS.REPAIR_TYPES_STATUS_${_vm.repair.times_status}`) : "-")+" ")])],1)]):_vm._e(),(_vm.repair.components_status)?_c('dl',{staticClass:"width_1-2 status"},[_c('dt',[_vm._v(_vm._s(_vm.$t("REPAIRS.COMPONENTS_STATUS")))]),_c('dd',[_c('repair-status-badge',{attrs:{"status":_vm.repair.components_status,"repair":_vm.repair}},[_vm._v(" "+_vm._s(_vm.$tc(`REPAIRS.REPAIR_TYPES_STATUS_${_vm.repair.components_status}`) ? _vm.$t(`REPAIRS.REPAIR_TYPES_STATUS_${_vm.repair.components_status}`) : "-")+" ")])],1)]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        _vm.$idExist(_vm.repair.customer?.id) &&
        !!_vm.repair.customer?.customer_type
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.CUSTOMER")))]),_c('dd',[_c('users',{attrs:{"users":[_vm.repair.customer]}})],1)]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        _vm.$idExist(_vm.repair.approver?.id) &&
        !!_vm.repair.approver?.email
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.APPROVER")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.repair.approver}})],1)]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_VEHICLES) &&
        !!_vm.repair.vehicle
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.VEHICLE")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.repair.vehicle}})],1)]):_vm._e(),_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("VEHICLES.ODOMETER")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.repair.odometer ?? _vm.repair.vehicle?.odometer)+" "+_vm._s(_vm.repair.vehicle?.odometer_unit)+" ")])]),_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.LOCATIONS")))]),_c('dd',[_c('locations',{attrs:{"locations":_vm.repair.allowedLocations}})],1)]),(!!_vm.repair.created_at)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("REPAIRS.CREATED_AT")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.repair.created_at, "LLLL"))+" ")])]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) && !!_vm.repair.creator
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("REPAIRS.CREATOR")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.repair.creator}})],1)]):_vm._e(),(!!_vm.repair.validated_at)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("REPAIRS.VALIDATED_AT")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.repair.validated_at, "LLLL"))+" ")])]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        !!_vm.repair.validatedBy
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("REPAIRS.VALIDATED_BY")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.repair.validatedBy}})],1)]):_vm._e(),(!!_vm.repair.assigned_at)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("REPAIRS.ASSIGNED_AT")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.repair.assigned_at, "LLLL"))+" ")])]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        !!_vm.repair.assignedToUsers?.length
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("REPAIRS.ASSIGNED_TO")))]),_c('dd',_vm._l((_vm.repair.assignedToUsers),function(user,idx){return _c('span',{key:idx},[_c('object-link',{attrs:{"object":user}}),_vm._v(" "+_vm._s(_vm.repair.assignedToUsers.length - 1 > idx ? "," : " ")),_c('br')],1)}),0)]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ROLES) &&
        !!_vm.repair.assignedToRoles?.length
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("REPAIRS.ASSIGNED_TO_GROUP")))]),_c('dd',_vm._l((_vm.repair.assignedToRoles),function(role,idx){return _c('span',{key:idx},[_c('object-link',{attrs:{"object":role}}),_vm._v(" "+_vm._s(_vm.repair.assignedToRoles.length - 1 > idx ? "," : " ")),_c('br')],1)}),0)]):_vm._e(),(!!_vm.repair.started_at)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("REPAIRS.STARTED_AT")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.repair.started_at, "LLLL"))+" ")])]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        !!_vm.repair.startedBy
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("REPAIRS.STARTED_BY")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.repair.startedBy}})],1)]):_vm._e(),(!!_vm.repair.ended_at)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("REPAIRS.ENDED_AT")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.repair.ended_at, "LLLL"))+" ")])]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) && !!_vm.repair.endedBy
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("REPAIRS.ENDED_BY")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.repair.endedBy}})],1)]):_vm._e(),(!!_vm.repair.started_times_at)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("REPAIRS.STARTED_TIMES_AT")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.repair.started_times_at, "LLLL"))+" ")])]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        !!_vm.repair.startedTimesBy
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("REPAIRS.STARTED_TIMES_BY")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.repair.startedTimesBy}})],1)]):_vm._e(),(!!_vm.repair.ended_times_at)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("REPAIRS.ENDED_TIMES_AT")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.repair.ended_times_at, "LLLL"))+" ")])]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        !!_vm.repair.endedTimesBy
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("REPAIRS.ENDED_TIMES_BY")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.repair.endedTimesBy}})],1)]):_vm._e(),(!!_vm.repair.started_approve_at)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("REPAIRS.STARTED_APPROVE_AT")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.repair.started_approve_at, "LLLL"))+" ")])]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        !!_vm.repair.startedApproveBy
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("REPAIRS.STARTED_APPROVE_BY")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.repair.startedApproveBy}})],1)]):_vm._e(),(!!_vm.repair.ended_approve_at)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("REPAIRS.ENDED_APPROVE_AT")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.repair.ended_approve_at, "LLLL"))+" ")])]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        !!_vm.repair.endedApproveBy
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("REPAIRS.ENDED_APPROVE_BY")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.repair.endedApproveBy}})],1)]):_vm._e(),(!!_vm.repair.reviewed_at)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("REPAIRS.REVIEWED_AT")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.repair.reviewed_at, "LLLL"))+" ")])]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        !!_vm.repair.reviewedBy
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("REPAIRS.REVIEWED_BY")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.repair.reviewedBy}})],1)]):_vm._e(),(!!_vm.repair.canceled_at)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(" "+_vm._s(_vm.$t("REPAIRS.CANCELED_AT"))+" ")]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.repair.canceled_at, "LLLL"))+" ")])]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        !!_vm.repair.canceledBy
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(" "+_vm._s(_vm.$t("REPAIRS.CANCELED_BY"))+" ")]),_c('dd',[_c('object-link',{attrs:{"object":_vm.repair.canceledBy}})],1)]):_vm._e(),(!!_vm.repair.completed_at)?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(" "+_vm._s(_vm.$t("REPAIRS.COMPLETED_AT"))+" ")]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.repair.completed_at, "LLLL"))+" ")])]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
        !!_vm.repair.completedBy
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(" "+_vm._s(_vm.$t("REPAIRS.COMPLETED_BY"))+" ")]),_c('dd',[_c('object-link',{attrs:{"object":_vm.repair.completedBy}})],1)]):_vm._e(),(
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
        !!_vm.repair.organization
      )?_c('dl',{staticClass:"width_1-2"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.ORGANIZATION")))]),_c('dd',[_c('organization',{attrs:{"organization":_vm.repair.organization}})],1)]):_vm._e()]),_c('inspection-confirmation-modal',{attrs:{"confirm-action":_vm.cancelRepair,"confirm-button-text":_vm.$t('COMMON.YES_CANCEL'),"loading":_vm.loading,"message":_vm.$t('REPAIRS.CANCEL_THIS_REPAIR'),"open":_vm.showRepairCancelModel,"modal-classes":"modal-secondary cancel"},on:{"update:open":function($event){_vm.showRepairCancelModel=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }