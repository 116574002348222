<template>
  <div class="item-inner" :id="`field_${fieldForm.id}`">
    <div class="item-inner-text">
      <p>{{ fieldForm.name }}</p>
    </div>
    <div class="item-inner-right">
      <div class="custom-loading" v-if="loading">
        <div class="custom-loading-icon"></div>
        <p>{{ $t("COMMON.LOADING") }}</p>
      </div>
      <base-button
        :class="{
          'is-filled': fieldModel.images.length > 0,
        }"
        :disabled="!fieldForm.can_upload_image"
        icon
        size="sm"
        @click="showPictureModal = true"
      >
        <i class="far fa-camera"></i>
      </base-button>

      <base-button
        :class="{ 'is-filled': !!fieldModel.comment }"
        :disabled="!fieldForm.can_add_comment"
        icon
        size="sm"
        @click="showActionModal = true"
      >
        <i v-if="!fieldModel.comment" class="far fa-comment"></i>
        <i v-if="!!fieldModel.comment" class="far fa-comment"></i>
      </base-button>

      <field-widget
        :fieldForm="fieldForm"
        :defaultValue="fieldData.value"
        :defaultActionValue="fieldData.action"
        :disabled="disabled"
        :commentValue="fieldData.comment"
        :touched="fieldData.touched"
        @change="valueChanged"
        @actionChanged="actionChanged"
      />
    </div>

    <action-comment-widget
      :showActionModal="showActionModal"
      :disabled="disabled"
      :actionValue="fieldData.action"
      :commentValue="fieldData.comment"
      :fieldValue="getValue()"
      @actionCanceled="cancelAction"
      @actionChanged="changeAction"
    />

    <modal
      :showClose="true"
      :show.sync="showPictureModal"
      modal-classes="picture"
    >
      <template slot="header">
        <h5 class="modal-title" id="inspection-picture">
          {{ $t("INSPECTIONS.ADD_PICTURES") }}
        </h5>
      </template>
      <div>
        <gallery-selector
          :label="$t('COMMON.PICTURES')"
          :defaultGallery="images"
          ressource_name="inspection_form_fields"
          :ressource_id="fieldData.id ? fieldData.id : 0"
          :field="fieldForm.name"
          :disabled="disabled"
          @galleryChanged="
            (gallery_urls) => {
              images = gallery_urls;
            }
          "
        />
      </div>
      <template slot="footer">
        <!-- <base-button
          type="secondary"
          class="btn close"
          @click="showPictureModal = false"
        >
          {{ $t("COMMON.CLOSE") }}
        </base-button> -->
        <base-button
          v-if="!disabled"
          type="primary"
          class="btn save"
          @click="addImages"
        >
          {{ $t("COMMON.SAVE") }}
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import { uniqueId } from "lodash";
import { cloneDeep, debounce, isEqual } from "lodash";
import { Button } from "element-ui";
import { INSPECTION_STATUS_IN_PROGRESS } from "@/constants/inspections";
import {
  INSPECTION_FORM_FIELD_TYPE_TIRE_STATE_WIDGET,
  INSPECTION_FORM_FIELD_TYPE_DRUM_THICKNESS_WIDGET,
  INSPECTION_FORM_FIELD_TYPE_PAD_THICKNESS_WIDGET,
} from "@/constants/inspectionForms";
import GallerySelector from "@/components/GallerySelector.vue";
import FieldWidget from "./fieldsWidgets/FieldWidget.vue";
import ActionCommentWidget from "./fieldsWidgets/ActionCommentWidget.vue";

export default {
  name: "inspection-view-inspection-section-sub-section-field-view",

  components: {
    [Button.name]: Button,
    GallerySelector,
    FieldWidget,
    ActionCommentWidget,
  },

  props: ["fieldForm", "fieldData", "disabled", "inspection", "settingLoading"],

  data() {
    const refKey = uniqueId([]);
    return {
      showPictureModal: false,
      showActionModal: false,
      fieldModel: cloneDeep(this.fieldData),
      images: cloneDeep(this.fieldData.images),
      refKey: refKey,
      loading: false,
    };
  },

  computed: {},

  created() {},

  methods: {
    valueChanged(value) {
      if (!isEqual(value, this.fieldModel.value)) {
        this.fieldModel.value = value;
        this.fieldModel.touched = true;
        this.fieldModel.comment = "";
        this.comment = "";
        this.radioComment = null;
        this.updateFieldDebounced();
      }
    },

    getValue() {
      if (
        [
          INSPECTION_FORM_FIELD_TYPE_DRUM_THICKNESS_WIDGET,
          INSPECTION_FORM_FIELD_TYPE_PAD_THICKNESS_WIDGET,
          INSPECTION_FORM_FIELD_TYPE_TIRE_STATE_WIDGET,
        ].includes(this.fieldForm.field_type)
      ) {
        return this.fieldData.value?.value;
      }
      return this.fieldData.value;
    },

    actionChanged(action, comment) {
      if (
        !isEqual(action, this.fieldModel.action) ||
        !isEqual(comment, this.fieldModel.comment)
      ) {
        this.fieldModel.action = action;
        this.fieldModel.comment = comment;
        this.updateFieldDebounced();
      }
    },

    addImages() {
      this.fieldModel.images = cloneDeep(this.images);
      this.showPictureModal = false;
      this.updateFieldDebounced();
    },

    cancelAction() {
      this.showActionModal = false;
    },

    changeAction(action, comment) {
      this.fieldModel.action = action;
      this.fieldModel.comment = comment;
      this.showActionModal = false;
      this.updateFieldDebounced();
    },

    updateFieldDebounced: debounce(function () {
      this.updateField();
    }, 500),

    async updateField() {
      if (
        this.settingLoading ||
        this.inspection.status != INSPECTION_STATUS_IN_PROGRESS
      ) {
        return;
      }
      this.loading = true;
      try {
        if (this.fieldModel.id == null) {
          const fieldData = {
            ...cloneDeep(this.fieldModel),
            inspection: this.inspection,
          };
          delete fieldData.id;
          await this.$store.dispatch("inspectionFields/add", fieldData);
        } else {
          await this.$store.dispatch("inspectionFields/update", {
            ...cloneDeep(this.fieldModel),
            inspection: this.inspection,
          });
        }
        const updatedField = await this.$store.getters[
          "inspectionFields/inspectionField"
        ];
        this.fieldModel = cloneDeep(updatedField);
        this.$emit("fieldChanged", cloneDeep(this.fieldModel));
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
      }
      this.loading = false;
    },
  },

  mounted() {},

  watch: {},
};
</script>
