<template>
  <div>
    <div class="add-inspection-steps">
      <div class="add-inspection-steps-header">
        <el-steps :active="active" finish-status="success" align-center>
          <el-step
            title="Inspection véhicule"
            @click.native="switchToStep(VEHICLE_STEP)"
            class="cursor-pointer"
          >
          </el-step>
          <el-step
            :title="$t('COMMON.CUSTOMER')"
            @click.native="switchToStep(CUSTOMER_STEP)"
            :class="{ 'cursor-pointer': canPassVehicleStep }"
          ></el-step>
          <el-step
            title="Validation"
            v-if="
              this.$currentUserCan(this.$permissions.PERM_APPROVE_INSPECTIONS)
            "
            @click.native="switchToStep(VALIDATION_STEP)"
            :class="{ 'cursor-pointer': canPassCustomerStep }"
          ></el-step>
          <el-step
            title="Assignation"
            v-if="canAssignInspection"
            @click.native="switchToStep(ASSIGNATION_STEP)"
            :class="{ 'cursor-pointer': canPassValidateStep }"
          ></el-step>
        </el-steps>
      </div>
      <div class="add-inspection-steps-content">
        <div v-if="active === VEHICLE_STEP" class="steps-content-1">
          <div class="step-content-body">
            <inspection-form-vehicle-step
              :inspection-data.sync="inspection"
              :form-errors="formErrors"
              :disabled="!canEditInspection"
              :disabled-inspection-form="!canEditInspectionForm"
              @onViewInspection="onViewInspection"
              @onCloseInspectionModal="onCloseInspectionModal"
            />
          </div>
          <div class="steps-content-footer">
            <Button
              v-if="canCancelInspection"
              class="cancel"
              @click="showCancelModal = true"
            >
              {{ $t("INSPECTIONS.CANCEL_INSPECTION") }}
            </Button>
            <Button
              class="next"
              @click="passVehicleStep"
              :disabled="!canPassVehicleStep"
              :class="{ 'opacity-5 cursor-not-allowed': !canPassVehicleStep }"
            >
              <i class="fas fa-spinner fa-spin mr-1" v-if="loading"></i>
              {{ $t("COMMON.NEXT") }}
              <i class="fal fa-long-arrow-right"></i>
            </Button>
            <Button
              class="prev"
              @click="onViewInspection(inspection)"
              v-if="canViewInspection"
            >
              {{ $t("INSPECTIONS.SHOW_INSPECTION") }}
            </Button>
          </div>
        </div>

        <div v-if="active === CUSTOMER_STEP" class="steps-content-2">
          <div class="step-content-body">
            <customer-step-form-component
              :object-data.sync="inspection"
              :form-errors="formErrors"
              :disabled="!canEditInspection"
            />
          </div>
          <div class="steps-content-footer">
            <Button
              v-if="canCancelInspection"
              class="cancel"
              @click="showCancelModal = true"
              >{{ $t("INSPECTIONS.CANCEL_INSPECTION") }}</Button
            >
            <Button class="prev" @click="prev">
              <i class="fal fa-long-arrow-left"></i>
              {{ $t("COMMON.PREVIOUS") }}
            </Button>
            <Button
              class="next"
              @click="passCustomerStep"
              :disabled="!canPassCustomerStep"
              :class="{ 'opacity-5 cursor-not-allowed': !canPassCustomerStep }"
            >
              <i class="fas fa-spinner fa-spin mr-1" v-if="loading"></i>
              {{ $t("COMMON.NEXT") }}
              <i class="fal fa-long-arrow-right"></i>
            </Button>
            <Button
              class="prev"
              @click="onViewInspection(inspection)"
              v-if="canViewInspection"
            >
              {{ $t("INSPECTIONS.SHOW_INSPECTION") }}
            </Button>
          </div>
        </div>

        <div v-if="active === VALIDATION_STEP" class="steps-content-3">
          <div class="step-content-body">
            <inspection-form-validation-step
              :inspection-data.sync="inspection"
            />
          </div>
          <div class="steps-content-footer">
            <Button
              v-if="canCancelInspection"
              class="cancel"
              @click="showCancelModal = true"
            >
              {{ $t("INSPECTIONS.CANCEL_INSPECTION") }}
            </Button>
            <Button class="prev" @click="prev">
              <i class="fal fa-long-arrow-left"></i>
              {{ $t("COMMON.PREVIOUS") }}
            </Button>
            <Button
              class="next"
              @click="next"
              v-if="canPassValidateStep && canAssignInspection"
            >
              {{ $t("COMMON.NEXT") }}
              <i class="fal fa-long-arrow-right"></i>
            </Button>

            <Button
              class="next"
              @click="close"
              v-if="canPassValidateStep && !canAssignInspection"
            >
              {{ $t("COMMON.CLOSE") }}
            </Button>

            <Button
              class="validate"
              @click="showValidateModal = true"
              v-if="!canPassValidateStep"
            >
              <i class="fas fa-spinner fa-spin mr-1" v-if="loading"></i>
              {{ $t("COMMON.VALIDATE") }}
            </Button>
            <Button
              class="prev"
              @click="onViewInspection(inspection)"
              v-if="canViewInspection"
            >
              {{ $t("INSPECTIONS.SHOW_INSPECTION") }}
            </Button>
          </div>
        </div>

        <div v-if="active === ASSIGNATION_STEP" class="steps-content-4">
          <div class="step-content-body">
            <inspection-form-assignation-step
              :inspection-data="inspection"
              :role.sync="role"
              :users.sync="users"
            />
          </div>
          <div class="steps-content-footer">
            <Button
              v-if="canCancelInspection"
              class="cancel"
              @click="showCancelModal = true"
            >
              {{ $t("INSPECTIONS.CANCEL_INSPECTION") }}
            </Button>
            <Button class="prev" @click="prev">
              <i class="fal fa-long-arrow-left"></i>
              {{ $t("COMMON.PREVIOUS") }}
            </Button>
            <Button
              :disabled="!canProceedAssignment"
              @click="showAssignModal = true"
              class="next"
              :class="{ 'opacity-5 cursor-not-allowed': !canProceedAssignment }"
            >
              {{ $t("COMMON.END") }}
            </Button>
            <Button
              class="prev"
              @click="onViewInspection(inspection)"
              v-if="canViewInspection"
            >
              {{ $t("INSPECTIONS.SHOW_INSPECTION") }}
            </Button>
          </div>
        </div>
      </div>
    </div>

    <modal :show.sync="showCancelModal" modal-classes="modal-secondary cancel">
      <template slot="header">
        <div class="header">
          <div class="header-left">
            <i class="fas fa-exclamation-triangle"></i>
            <span> {{ $t("COMMON.IMPORTANT") }} </span>
          </div>
          <div class="header-right">
            <button @click="showCancelModal = false">
              <i class="fal fa-times"></i>
            </button>
          </div>
        </div>
      </template>
      <p>{{ $t("INSPECTIONS.CANCEL_THIS_INSPECTION") }}</p>
      <template slot="footer">
        <Button class="no" @click="showCancelModal = false">
          {{ $t("COMMON.NO") }}
        </Button>
        <Button class="yes" @click="cancelInspection">
          <i class="fas fa-spinner fa-spin mr-1" v-if="loading"></i>
          {{ $t("COMMON.YES_CANCEL") }}
        </Button>
      </template>
    </modal>

    <modal
      :show.sync="showValidateModal"
      modal-classes="modal-secondary validate"
    >
      <template slot="header">
        <div class="header">
          <div class="header-left">
            <i class="fas fa-exclamation-triangle"></i>
            <span> {{ $t("COMMON.IMPORTANT") }} </span>
          </div>
          <div class="header-right">
            <button @click="showValidateModal = false">
              <i class="fal fa-times"></i>
            </button>
          </div>
        </div>
      </template>
      <p>{{ $t("INSPECTIONS.APPROVE_THIS_INSPECTION") }}</p>
      <template slot="footer">
        <Button class="no" @click="showValidateModal = false">
          {{ $t("COMMON.NO") }}
        </Button>
        <Button class="yes" @click="ValidateInspection">
          <i class="fas fa-spinner fa-spin mr-1" v-if="loading"></i>
          {{ $t("COMMON.YES_VALIDATE") }}
        </Button>
      </template>
    </modal>

    <modal
      :show.sync="showAssignModal"
      modal-classes="modal-secondary validate"
    >
      <template slot="header">
        <div class="header">
          <div class="header-left">
            <i class="fas fa-exclamation-triangle"></i>
            <span> {{ $t("COMMON.IMPORTANT") }} </span>
          </div>
          <div class="header-right">
            <button @click="showAssignModal = false">
              <i class="fal fa-times"></i>
            </button>
          </div>
        </div>
      </template>
      <p>{{ $t("INSPECTIONS.ASSIGN_THIS_INSPECTION") }}</p>
      <template slot="footer">
        <Button class="no" @click="showAssignModal = false">
          {{ $t("COMMON.NO") }}
        </Button>
        <Button class="yes" @click="assignInspection">
          <i class="fas fa-spinner fa-spin mr-1" v-if="loading"></i>
          {{ $t("COMMON.YES_ASSIGN") }}
        </Button>
      </template>
    </modal>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import alertLeaveMixin from "@/mixins/alert-leave-mixin";
import { Checkbox, CheckboxGroup, Radio, Step, Steps } from "element-ui";
import InspectionFormVehicleStep from "./InspectionFormVehicleStep.vue";
import InspectionFormValidationStep from "./InspectionFormValidationStep.vue";
import InspectionFormAssignationStep from "./InspectionFormAssignationStep.vue";
import {
  INSPECTION_STATUS_DRAFT,
  INSPECTION_STATUS_PENDING,
  INSPECTION_STATUS_ASSIGNED,
  INSPECTION_STATUS_COMPLETED,
  INSPECTION_STATUS_CANCELED,
  INSPECTION_STATUS_IN_PROGRESS,
} from "@/constants/inspections";
import requestErrorMixin from "@/mixins/request-error-mixin";
import swal from "sweetalert2";
import {
  CUSTOMER_TYPE_COMPANY,
  CUSTOMER_TYPE_INDIVIDUAL,
} from "@/constants/customers";
import CustomerStepFormComponent from "@/components/CustomerStepFormComponent.vue";

export default {
  layout: "DashboardLayout",

  name: "inspection-form",

  components: {
    [Steps.name]: Steps,
    [Step.name]: Step,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [Radio.name]: Radio,
    InspectionFormVehicleStep,
    InspectionFormValidationStep,
    InspectionFormAssignationStep,
    CustomerStepFormComponent,
  },

  mixins: [alertLeaveMixin, requestErrorMixin],

  props: ["inspectionData"],

  data() {
    const inspection = cloneDeep(this.inspectionData);
    const inspectionBackup = cloneDeep(this.inspectionData);
    const VEHICLE_STEP = 0;
    const CUSTOMER_STEP = 1;
    const VALIDATION_STEP = 2;
    const ASSIGNATION_STEP = 3;
    return {
      VEHICLE_STEP,
      CUSTOMER_STEP,
      ASSIGNATION_STEP,
      VALIDATION_STEP,
      inspection,
      formErrors: null,
      loading: false,
      active: 0,
      showCancelModal: false,
      showValidateModal: false,
      inspectionBackup,
      showAssignModal: false,
      users: [],
      INSPECTION_STATUS_DRAFT,
      role: "-1",
    };
  },

  created() {
    this.setActiveStep();
    this.fillAssignationVariables(this.inspectionData);
  },

  computed: {
    canPassVehicleStep() {
      return (
        !this.loading &&
        this.$idExist(this.inspection.organization?.id) &&
        // this.$idExist(this.inspection.vehicle?.id) &&
        this.$idExist(this.inspection.inspectionForm?.id)
      );
    },

    canPassCustomerStep() {
      return (
        this.canPassVehicleStep &&
        (this.$idExist(this.inspection.customer?.id) ||
          this.$idExist(this.inspection.approver?.id) ||
          !!this.inspection.customer?.organization)
      );
    },

    canPassValidateStep() {
      return (
        this.canPassCustomerStep &&
        this.inspection.status != INSPECTION_STATUS_DRAFT
      );
    },

    activeStep() {
      let active;
      if (this.canPassValidateStep && this.canAssignInspection) {
        active = this.ASSIGNATION_STEP;
      } else if (this.canPassCustomerStep) {
        active = this.VALIDATION_STEP;
      } else if (
        this.canPassVehicleStep &&
        this.$idExist(this.inspection.vehicle?.id)
      ) {
        active = this.CUSTOMER_STEP;
      } else {
        active = this.VEHICLE_STEP;
      }
      return active;
    },

    canEditInspection() {
      return (
        ![INSPECTION_STATUS_COMPLETED, INSPECTION_STATUS_CANCELED].includes(
          this.inspection.status
        ) &&
        (this.$currentUserCan(this.$permissions.PERM_EDIT_INSPECTIONS) ||
          this.$currentUserCan(this.$permissions.PERM_APPROVE_INSPECTIONS) ||
          this.$currentUserCan(this.$permissions.PERM_ASSIGN_INSPECTIONS))
      );
    },

    canEditInspectionForm() {
      return ![
        INSPECTION_STATUS_COMPLETED,
        INSPECTION_STATUS_CANCELED,
        INSPECTION_STATUS_IN_PROGRESS,
      ].includes(this.inspection.status);
    },

    canAssignInspection() {
      return this.$currentUserCan(this.$permissions.PERM_ASSIGN_INSPECTIONS);
    },

    canCancelInspection() {
      return (
        this.$idExist(this.inspection?.id) &&
        this.$currentUserCan(this.$permissions.PERM_CANCEL_INSPECTIONS) &&
        [
          INSPECTION_STATUS_DRAFT,
          INSPECTION_STATUS_PENDING,
          INSPECTION_STATUS_ASSIGNED,
        ].includes(this.inspection.status)
      );
    },
    canProceedAssignment() {
      return (
        [INSPECTION_STATUS_ASSIGNED, INSPECTION_STATUS_PENDING].includes(
          this.inspection.status
        ) &&
        this.canAssignInspection &&
        ((this.role == "-1" && this.users.length > 0) || this.role != "-1")
      );
    },

    canViewInspection() {
      return (
        !!this.inspection &&
        !!this.inspection.status &&
        ![INSPECTION_STATUS_DRAFT, INSPECTION_STATUS_PENDING].includes(
          this.inspection.status
        ) &&
        this.$currentUserCan(this.$permissions.PERM_VIEW_INSPECTIONS)
      );
    },

    isRoleAssignment() {
      return this.role != "-1";
    },
  },

  methods: {
    prev() {
      this.active = Math.max(this.active - 1, this.VEHICLE_STEP);
    },

    next() {
      const active = Math.min(this.active + 1, this.ASSIGNATION_STEP);

      if (active == this.ASSIGNATION_STEP && !this.canAssignInspection) return;

      this.active = active;
    },

    close() {
      this.$emit("onCloseInspectionModal");
    },

    switchToStep(step) {
      if (step == this.ASSIGNATION_STEP && !this.canPassValidateStep) {
        return;
      }

      if (step == this.VALIDATION_STEP && !this.canPassCustomerStep) {
        return;
      }

      if (
        step == this.CUSTOMER_STEP &&
        (!this.canPassVehicleStep ||
          !this.$idExist(this.inspectionBackup.vehicle?.id))
      ) {
        return;
      }

      this.active = step;
    },

    setActiveStep() {
      this.active = this.activeStep;
    },

    async passVehicleStep() {
      if (!this.canEditInspection) {
        this.next();
        return;
      }

      this.loading = true;
      swal.showLoading();

      try {
        await this.handleVehicleFormSubmit(this.inspection.vehicle);

        const inspectionData = cloneDeep(this.inspection);

        if (!this.canEditInspectionForm) {
          delete inspectionData.inspectionForm;
        }

        if (!this.$idExist(inspectionData.request?.id)) {
          delete inspectionData.request;
        }

        if (!this.$idExist(inspectionData.customer?.id)) {
          delete inspectionData.customer;
        }

        if (!this.$idExist(inspectionData.approver?.id)) {
          delete inspectionData.approver;
        }

        await this.submitInspectionForm(inspectionData);

        const inspection = await this.$store.getters["inspections/inspection"];

        if (this.$idExist(inspectionData?.id)) {
          this.inspection = { ...inspectionData, ...inspection };
          this.$emit("formChanged");
          this.next();
        } else {
          this.$emit("onEditInspection", inspection);
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response?.data?.errors;
      } finally {
        this.loading = false;
        swal.close();
      }
    },

    async passCustomerStep() {
      if (!this.canEditInspection) {
        this.next();
        return;
      }

      this.loading = true;
      swal.showLoading();
      try {
        if (!this.$idExist(this.inspection.approver?.id)) {
          if (!!this.inspection.customer?.organization) {
            await this.handleCustomerFormSubmit(this.inspection.customer);
          }
          this.inspection.approver = null;
        } else {
          this.inspection.customer = null;
        }

        const inspectionData = cloneDeep(this.inspection);

        if (!this.$idExist(inspectionData.request?.id)) {
          delete inspectionData.request;
        }

        if (!this.$idExist(inspectionData.request?.id)) {
          delete inspectionData.request;
        }

        await this.submitInspectionForm(inspectionData);

        const inspection = await this.$store.getters["inspections/inspection"];

        this.inspection = { ...inspectionData, ...inspection };

        this.$emit("formChanged");

        this.next();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response?.data?.errors;
      } finally {
        this.loading = false;
        swal.close();
      }
    },

    async ValidateInspection() {
      this.loading = true;
      swal.showLoading();
      try {
        if (!this.$idExist(this.inspection.customer?.id)) {
          this.inspection.customer = null;
        }

        if (!this.$idExist(this.inspection.approver?.id)) {
          this.inspection.approver = null;
        }

        await this.submitInspectionForm(this.inspection);

        await this.$store.dispatch("inspections/approve", {
          inspectionId: this.inspection.id,
          data: {},
        });
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("INSPECTIONS.INSPECTION_APPROVED"),
        });

        const inspection = await this.$store.getters["inspections/inspection"];

        this.inspection = { ...this.inspection, ...inspection };

        this.$emit("formChanged");

        if (!this.canAssignInspection) {
          this.close();
        } else {
          this.next();
        }

        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error, false);

        await this.getInspection(this.inspection?.id);

        this.formErrors = this.refactorError(error.response?.data?.errors);

        await this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });

        this.setActiveStep();
      } finally {
        this.loading = false;
        this.showValidateModal = false;
      }
    },

    async cancelInspection() {
      swal.showLoading();

      try {
        await this.$store.dispatch("inspections/cancel", this.inspection.id);

        const inspection = await this.$store.getters["inspections/inspection"];

        this.inspection = { ...this.inspection, ...inspection };

        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("INSPECTIONS.INSPECTION_CANCELED"),
        });

        this.$emit("onViewInspection");
        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.showCancelModal = false;
      }
    },

    async assignInspection() {
      if (!this.canProceedAssignment) {
        return;
      }

      this.loading = true;
      swal.showLoading();

      try {
        await this.$store.dispatch("inspections/assign", {
          inspectionId: this.inspection.id,
          data: this.isRoleAssignment
            ? { roles: [this.role] }
            : { users: this.users },
        });

        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("INSPECTIONS.INSPECTION_ASSIGNED"),
        });

        this.$emit("onViewInspection");
      } catch (error) {
        await this.showRequestError(error, false);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
        this.showAssignModal = false;
      }
    },

    async submitInspectionForm(inspectionData) {
      // if (!inspectionData.customer) {
      //   inspectionData.customer = cloneDeep(inspectionData.vehicle.customer);
      // }
      // if (!inspectionData.approver) {
      //   inspectionData.approver = cloneDeep(inspectionData.vehicle.mecanic);
      // }

      if (this.$idExist(inspectionData?.id)) {
        await this.$store.dispatch("inspections/update", inspectionData);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("INSPECTIONS.INSPECTION_UPDATED"),
        });
      } else {
        delete inspectionData.id;
        await this.$store.dispatch("inspections/add", inspectionData);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("INSPECTIONS.INSPECTION_ADDED"),
        });
      }
    },

    async handleVehicleFormSubmit(vehicleData) {
      vehicleData = { ...this.inspection.vehicle, ...vehicleData };
      let vehicle = null;

      if (!this.$idExist(vehicleData.customer?.id)) {
        delete vehicleData.customer;
      }
      if (!this.$idExist(vehicleData.mecanic?.id)) {
        delete vehicleData.mecanic;
      }

      if (this.$idExist(vehicleData?.id)) {
        delete vehicleData.organization;
        await this.$store.dispatch("vehicles/update", vehicleData);
        vehicle = await this.$store.getters["vehicles/vehicle"];
      } else {
        delete vehicleData.id;
        await this.$store.dispatch("vehicles/add", vehicleData);
        vehicle = await this.$store.getters["vehicles/vehicle"];
      }

      this.inspection.vehicle = { ...this.inspection.vehicle, ...vehicle };
      this.inspection.organization = {
        ...this.inspection.organization,
        ...vehicle.organization,
      };

      if (vehicleData.allowedLocations.length) {
        this.inspection.allowedLocations = cloneDeep(
          vehicleData.allowedLocations
        );
      }

      this.inspection.gallery = vehicle?.gallery;
      this.inspection.odometer = vehicle?.odometer;
      this.inspection.odometer_unit = vehicle?.odometer_unit;
      this.inspection.registration_number = vehicle?.registration_number;
      this.inspection.color = vehicle?.color;
    },

    async handleCustomerFormSubmit(customerData) {
      customerData = { ...this.inspection.customer, ...customerData };

      if (customerData.customer_type === CUSTOMER_TYPE_INDIVIDUAL) {
        customerData.company_name = "N/A";
        customerData.billing_company_name = "N/A";
      } else if (customerData.customer_type === CUSTOMER_TYPE_COMPANY) {
        customerData.firstname = "N/A";
        customerData.lastname = "N/A";
        customerData.billing_firstname = "N/A";
        customerData.billing_lastname = "N/A";
      }

      let customer = null;

      if (this.$idExist(customerData?.id)) {
        if (this.$currentUserCan(this.$permissions.PERM_EDIT_CUSTOMERS)) {
          await this.$store.dispatch("customers/update", customerData);

          customer = await this.$store.getters["customers/customer"];

          this.inspection.customer = {
            ...this.inspection.customer,
            ...customer,
          };
        }
      } else {
        if (this.$currentUserCan(this.$permissions.PERM_CREATE_CUSTOMERS)) {
          delete customerData.id;
          await this.$store.dispatch("customers/add", customerData);

          customer = await this.$store.getters["customers/customer"];

          this.inspection.customer = {
            ...this.inspection.customer,
            ...customer,
          };
        }
      }
    },

    async getInspection(inspectionId) {
      try {
        await this.$store.dispatch("inspections/get", inspectionId);
        const inspection = this.$store.getters["inspections/inspection"];
        this.inspection = { ...this.inspection, ...inspection };
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    refactorError(errors) {
      return errors.map((error) => {
        let err = { ...error };
        if (error?.source?.pointer === "/data") {
          if (
            error.detail.includes("customer") ||
            error.detail.includes("client")
          ) {
            err = {
              ...err,
              source: {
                pointer: "/data/relationships/customer/data/id",
              },
            };
          }
          if (
            error.detail.includes("vehicle") ||
            error.detail.includes("véhicule")
          ) {
            err = {
              ...err,
              source: {
                pointer: "/data/relationships/vehicle/data/id",
              },
            };
          }
          if (
            error.detail.includes("form") ||
            error.detail.includes("formulaire")
          ) {
            err = {
              ...err,
              source: {
                pointer: "/data/relationships/inspectionForm/data/id",
              },
            };
          }
        }
      });
    },

    fillAssignationVariables(inspection) {
      this.role = inspection?.assignedToRoles?.length
        ? inspection.assignedToRoles[0].id
        : "-1";
      this.users = inspection?.assignedToUsers?.map((user) => user.id) ?? [];
    },
    onViewInspection(inspection = null) {
      inspection = inspection ?? this.inspection;
      this.$emit("onViewInspection", inspection);
    },

    onCloseInspectionModal() {
      this.$emit("onCloseInspectionModal");
    },
  },

  watch: {
    formErrors(errors) {
      this.formErrorsValue = cloneDeep(errors);
    },
    inspectionData: {
      handler: function () {
        this.inspection = { ...this.inspectionData };
        this.inspectionBackup = { ...this.inspectionData };
        this.fillAssignationVariables(this.inspectionData);
      },
      deep: true,
    },
  },
};
</script>
