<template>
  <div class="field-container">
    <div
      v-if="fieldForm.field_type === INSPECTION_FORM_FIELD_TYPE_SELECT_SINGLE"
      class="form-field"
    >
      <base-input>
        <div class="radio-group">
          <div
            v-for="(option, index) in fieldForm.points_threshold"
            :key="index"
          >
            <base-radio
              :name="option.option"
              :value="option.option"
              v-model="fieldValue"
              :disabled="disabled"
            >
              {{ option.option }}
            </base-radio>
          </div>
        </div>
      </base-input>
    </div>

    <div
      v-if="fieldForm.field_type === INSPECTION_FORM_FIELD_TYPE_SELECT_MULTIPLE"
      class="form-field"
    >
      <base-input>
        <div class="radio-group">
          <div
            v-for="(option, index) in fieldForm.points_threshold"
            :key="index"
          >
            <base-checkbox
              v-model="fieldValue[option.option]"
              :disabled="disabled"
            >
              {{ option.option }}
            </base-checkbox>
          </div>
        </div>
      </base-input>
    </div>

    <div
      v-if="fieldForm.field_type === INSPECTION_FORM_FIELD_TYPE_NUMBER"
      class="form-field"
    >
      <base-input
        :placeholder="fieldForm.name"
        v-model="fieldValue"
        type="number"
        :step="numberStep"
        :disabled="disabled"
      >
      </base-input>
    </div>

    <div
      v-if="fieldForm.field_type === INSPECTION_FORM_FIELD_TYPE_TEXT"
      class="form-field"
    >
      <base-input
        :placeholder="fieldForm.name"
        v-model="fieldValue"
        type="text"
        :disabled="disabled"
      >
      </base-input>
    </div>

    <div
      v-if="fieldForm.field_type === INSPECTION_FORM_FIELD_TYPE_RANGE_SLIDER"
      class="form-field"
    >
      <div class="slider-group">
        <base-slider
          v-model="fieldValue"
          :range="getSliderRange()"
          :disabled="disabled"
        ></base-slider>
        <span class="range-slider-value">{{ fieldValue }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { Select, Option, Collapse, CollapseItem } from "element-ui";
import {
  INSPECTION_FORM_FIELD_TYPE_SELECT_SINGLE,
  INSPECTION_FORM_FIELD_TYPE_SELECT_MULTIPLE,
  INSPECTION_FORM_FIELD_TYPE_NUMBER,
  INSPECTION_FORM_FIELD_TYPE_TEXT,
  INSPECTION_FORM_FIELD_TYPE_RANGE_SLIDER,
} from "@/constants/inspectionForms";
import { BaseSlider, BaseCheckbox, BaseRadio } from "@/components";

export default {
  name: "default-field-widget",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    BaseSlider,
    BaseCheckbox,
    BaseRadio,
  },

  mixins: [],

  props: ["fieldForm", "defaultValue", "disabled"],

  data() {
    return {
      INSPECTION_FORM_FIELD_TYPE_SELECT_SINGLE,
      INSPECTION_FORM_FIELD_TYPE_SELECT_MULTIPLE,
      INSPECTION_FORM_FIELD_TYPE_NUMBER,
      INSPECTION_FORM_FIELD_TYPE_TEXT,
      INSPECTION_FORM_FIELD_TYPE_RANGE_SLIDER,
      fieldValue: this.getFormattedValue(),
    };
  },

  computed: {
    numberStep() {
      if (
        ["odometer", "odomètre", "odometre"].includes(
          this.fieldForm.name.toLowerCase()
        )
      ) {
        return 1;
      }
      return 0.01;
    },
  },

  created() {},

  methods: {
    getFormattedValue() {
      let value = cloneDeep(this.defaultValue);
      const type = this.fieldForm.field_type;
      if (!value) {
        if (type == INSPECTION_FORM_FIELD_TYPE_SELECT_MULTIPLE) {
          value = {};
          for (const option of this.fieldForm.points_threshold) {
            value[option.option] = false;
          }
          this.$emit("change", value);
        } else if (type == INSPECTION_FORM_FIELD_TYPE_RANGE_SLIDER) {
          value = this.getFormattedOptionsRanges(this.fieldForm)[0][0];
          this.$emit("change", value);
        }
      }
      return value;
    },

    getFormattedOptionsRanges(fieldForm) {
      const ranges = fieldForm.points_threshold
        .map((item) => {
          const optionsValues = item.option
            .split("-")
            .map((item) => parseFloat(item));
          return optionsValues;
        })
        .filter((item) => {
          if (!Array.isArray(item)) {
            return false;
          }
          if (item.length != 2) {
            return false;
          }
          return true;
        })
        .sort((a, b) => {
          return a[0] - b[0];
        });
      return ranges;
    },

    getSliderRange() {
      const ranges = this.getFormattedOptionsRanges(this.fieldForm);
      return {
        min: ranges[0][0],
        max: ranges[ranges.length - 1][1],
      };
    },
  },

  mounted() {},

  watch: {
    defaultValue() {
      this.fieldValue = this.getFormattedValue(this.defaultValue);
    },
    defaultActionValue() {
      this.actionValue = this.defaultActionValue;
    },
    fieldValue: {
      handler: function (fieldValue) {
        let value = cloneDeep(fieldValue);
        this.$emit("change", value);
      },
      deep: true,
    },
  },
};
</script>
