<template>
  <div>
    <div class="inspection-list">
      <div class="inspection-list-header">
        <div class="start-inspection" v-if="canStartInspection">
          <button @click="showStartModal = true">
            {{ $t("INSPECTIONS.START_INSPECTION") }}
          </button>
        </div>
        <div class="start-inspection" v-if="canTakeOverInspection">
          <button @click="showTakeOverModal = true">
            {{ $t("INSPECTIONS.TAKE_OVER_INSPECTION") }}
          </button>
        </div>
        <inspection-view-inspection-edit-inspection
          :inspection-data="inspection"
          @onViewInspection="onViewInspection"
          v-if="canEditInspection"
        />
      </div>

      <div
        class="inspection-list-content"
        v-if="
          [INSPECTION_STATUS_IN_PROGRESS, INSPECTION_STATUS_COMPLETED].includes(
            inspection.status
          )
        "
      >
        <!-- Add class disable to disable items -->
        <div
          v-for="section in inspection.inspectionForm.inspectionFormSections.filter(
            (item) => canShowElement(item, inspectionFieldsModel)
          )"
          :key="section.id"
          class="inspection-list-content-item"
          v-on:click="openInspectionSection(section)"
        >
          <div class="inspection-list-content-item-left">
            <div class="inspection-list-content-item-left-image">
              <i class="fal fa-camera"></i>
              <img
                v-if="section.icon"
                :src="section.icon"
                :alt="section.name"
              />
            </div>
            <h4>{{ section.name }}</h4>
          </div>
          <div class="inspection-list-content-item-right">
            <ul>
              <li class="percent" v-if="isSectionTouched(section, inspection)">
                {{ getSectionScore(section) }}%
              </li>
              <li
                v-if="
                  isSectionTouched(section, inspection) &&
                  !isSectionFillComplete(section, inspectionFieldsModel)
                "
              >
                <i class="far fa-times-circle" style="color: red"></i>
              </li>
              <li
                v-if="
                  isSectionTouched(section, inspection) &&
                  isSectionFillComplete(section, inspectionFieldsModel)
                "
              >
                <i class="far fa-check-circle"></i>
              </li>
              <li
                v-if="
                  [
                    INSPECTION_STATUS_IN_PROGRESS,
                    INSPECTION_STATUS_COMPLETED,
                  ].includes(inspection?.status)
                "
              >
                <base-button class="details" icon size="sm">
                  <div
                    v-if="loading && delayedOpenedSection?.id == section.id"
                    class="custom-loading-icon"
                  ></div>
                  <i v-else class="far fa-arrow-right"></i>
                </base-button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="inspection-list-footer">
        <base-button
          v-if="canGenerateReport"
          @click="downloadReport"
          class="report"
          icon
          size="sm"
        >
          {{ $t("INSPECTIONS.GENERATE_REPORT") }}
          <img src="/img/sm-file-pdf-outline.svg" alt="icon" />
        </base-button>

        <base-button
          v-if="canCompleteInspection"
          class="done"
          icon
          size="sm"
          @click="startCompleteInspection"
        >
          {{ $t("INSPECTIONS.COMPLETE_INSPECTION") }}
          <img src="/img/sm-check.svg" alt="icon" />
        </base-button>
      </div>
    </div>

    <div
      v-if="inspectionSectionModalOpened && openedSection"
      class="resizable-wrapper"
      v-bind:class="[inspectionSectionModalOpened ? 'show' : 'hide']"
    >
      <inspection-view-inspection-section-modal
        :loading="loading"
        :sectionForm="openedSection"
        :sectionData="inspectionFieldsModel[openedSection?.id]"
        :inspection="inspection"
        :isLastSection="isLastSection"
        :isFirstSection="isFirstSection"
        :disabled="!canDoInspection"
        :inspectionData="inspectionFieldsModel"
        @onCloseModal="closeInspectionSection"
        @onChangeSection="openInspectionSection"
        @onNextSection="openNextInspectionSection"
        @onPrevSection="openPrevInspectionSection"
        @onInspectionUpdated="openNextInspectionSection"
        @onEndForm="onEndForm"
        @sectionChanged="
          (value) => {
            sectionChanged(openedSection.id, value);
          }
        "
      />
    </div>

    <inspection-view-inspection-untouched-fields
      v-if="showUntouchedFieldModal"
      :inspection="inspection"
      :inspectionFieldsModel="inspectionFieldsModel"
      @onCloseModal="
        () => {
          showUntouchedFieldModal = false;
        }
      "
      @onOpenSection="
        (section) => {
          openInspectionSection(section);
          showUntouchedFieldModal = false;
        }
      "
    />

    <inspection-view-inspection-reminders
      v-if="showRemindersModal"
      :inspection="inspection"
      @onCloseModal="
        () => {
          showRemindersModal = false;
        }
      "
      @onContinueStep="
        (section) => {
          showRemindersModal = false;
          remindersValidated = true;
          showCommentAndSignModal = true;
        }
      "
    />

    <inspection-view-inspection-comment-and-sign
      v-if="showCommentAndSignModal"
      :inspection="inspection"
      @onCloseModal="
        () => {
          remindersValidated = false;
          showCommentAndSignModal = false;
        }
      "
      @onSignAndSend="completeInspection"
    />

    <modal
      :show.sync="showCompleteModal"
      modal-classes="modal-secondary estimation"
    >
      <div class="icon">
        <img src="/img/check-green.svg" alt="icon" />
      </div>
      <p>{{ $t("INSPECTIONS.COMPLETE_INFORMATIONS_SENT") }}</p>
      <template slot="footer">
        <Button class="no" @click="showCompleteModal = false">
          {{ $t("INSPECTIONS.COMPLETE") }}
        </Button>
        <Button class="no" @click="downloadReport">
          {{ $t("INSPECTIONS.GENERATE_REPORT") }}
        </Button>
        <Button class="yes" v-if="canViewEstimate" @click="viewEstimate">
          {{ $t("ESTIMATES.SHOW_ESTIMATE") }}
        </Button>
      </template>
    </modal>

    <inspection-confirmation-modal
      :confirm-action="startInspection"
      :confirm-button-text="$t('COMMON.YES_START')"
      :loading="loading"
      :message="$t('INSPECTIONS.START_THIS_INSPECTION')"
      :open.sync="showStartModal"
      modal-classes="modal-secondary start small"
    />

    <inspection-confirmation-modal
      :confirm-action="takeOverInspection"
      :confirm-button-text="$t('INSPECTIONS.YES_TAKE_OVER')"
      :loading="loading"
      :message="
        $t('INSPECTIONS.TAKE_OVER_THIS_INSPECTION', {
          name: $objectDenomination(inspection.startedBy),
        })
      "
      :open.sync="showTakeOverModal"
      modal-classes="modal-secondary take-over start"
    />

    <inspection-confirmation-modal
      :confirm-action="() => (showAlertModal = false)"
      :confirm-button-text="$t('COMMON.OK')"
      :loading="loading"
      :message="$t('INSPECTIONS.IN_PROGRESS_WARNING')"
      :open.sync="showAlertModal"
      :show-cancel-button="false"
      modal-classes="modal-secondary take-over start"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { mapGetters } from "vuex";
import swal from "sweetalert2";
import {
  canShowElement,
  isSectionTouched,
  isSectionFillComplete,
  getUntouchedFields,
} from "@/helpers/formFieldHelper";
import {
  Button,
  Select,
  Option,
  Checkbox,
  CheckboxGroup,
  Input,
} from "element-ui";
import {
  INSPECTION_STATUS_CANCELED,
  INSPECTION_STATUS_COMPLETED,
  INSPECTION_STATUS_IN_PROGRESS,
  INSPECTION_STATUS_ASSIGNED,
} from "@/constants/inspections";
import InspectionConfirmationModal from "@/components/InspectionConfirmationModal.vue";
import InspectionViewInspectionSectionModal from "./InspectionViewInspectionSectionModal.vue";
import InspectionViewInspectionUntouchedFields from "./InspectionViewInspectionUntouchedFields.vue";
import InspectionViewInspectionReminders from "./InspectionViewInspectionReminders.vue";
import InspectionViewInspectionCommentAndSign from "./InspectionViewInspectionCommentAndSign.vue";
import defaultInspectionField from "../defaultInspectionField";
import InspectionViewInspectionEditInspection from "./InspectionViewInspectionEditInspection.vue";

export default {
  name: "inspection-view-inspection",

  components: {
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [Input.name]: Input,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    InspectionViewInspectionSectionModal,
    InspectionViewInspectionUntouchedFields,
    InspectionViewInspectionReminders,
    InspectionViewInspectionCommentAndSign,
    InspectionConfirmationModal,
    InspectionViewInspectionEditInspection,
  },

  props: ["inspection", "loading"],

  data() {
    return {
      inspectionFieldsModel: this.getMappedInspectionFieldsModel(),
      inspectionSectionModalOpened: false,
      openedSection: null,
      delayedOpenedSection: null,
      INSPECTION_STATUS_CANCELED: INSPECTION_STATUS_CANCELED,
      INSPECTION_STATUS_COMPLETED: INSPECTION_STATUS_COMPLETED,
      INSPECTION_STATUS_IN_PROGRESS,
      showUntouchedFieldModal: false,
      showRemindersModal: false,
      showCommentAndSignModal: false,
      showCompleteModal: false,
      remindersValidated: false,
      showStartModal: false,
      showTakeOverModal: false,
      showAlertModal: false,
      canShowElement: canShowElement,
      isSectionTouched: isSectionTouched,
      isSectionFillComplete: isSectionFillComplete,
    };
  },

  computed: {
    ...mapGetters("profile", { me: "me" }),

    isLastSection() {
      const inspectionFormSections =
        this.inspection.inspectionForm.inspectionFormSections;
      const openedSectionIndex = inspectionFormSections.findIndex(
        (item) => item.id == this.openedSection.id
      );
      if (openedSectionIndex + 1 == inspectionFormSections.length) {
        return true;
      }
      return false;
    },

    isFirstSection() {
      const inspectionFormSections =
        this.inspection.inspectionForm.inspectionFormSections;
      const openedSectionIndex = inspectionFormSections.findIndex(
        (item) => item.id == this.openedSection.id
      );
      if (openedSectionIndex == 0) {
        return true;
      }
      return false;
    },

    canDoInspection() {
      return (
        this.inspection.status == INSPECTION_STATUS_IN_PROGRESS &&
        this.me.id == this.inspection.startedBy.id
      );
    },

    canStartInspection() {
      return (
        !!this.inspection &&
        this.inspection.status == INSPECTION_STATUS_ASSIGNED &&
        this.$currentUserCan(this.$permissions.PERM_START_INSPECTIONS)
      );
    },

    canTakeOverInspection() {
      return (
        !!this.inspection &&
        this.inspection.status == INSPECTION_STATUS_IN_PROGRESS &&
        this.$currentUserCan(this.$permissions.PERM_START_INSPECTIONS) &&
        this.me.id != this.inspection.startedBy?.id &&
        this.$currentUserCan(this.$permissions.PERM_TAKE_OVER_INSPECTIONS)
      );
    },

    canCompleteInspection() {
      return (
        !!this.inspection &&
        this.inspection.status == INSPECTION_STATUS_IN_PROGRESS &&
        this.me.id == this.inspection.startedBy?.id
      );
    },

    canGenerateReport() {
      return (
        !!this.inspection &&
        this.inspection.status == INSPECTION_STATUS_COMPLETED
      );
    },
    canViewEstimate() {
      return (
        !!this.inspection.estimation &&
        this.$currentUserCan(this.$permissions.PERM_VIEW_ESTIMATIONS)
      );
    },
    canEditInspection() {
      return (
        this.inspection.status == INSPECTION_STATUS_ASSIGNED &&
        this.$currentUserCan(this.$permissions.PERM_EDIT_INSPECTIONS)
      );
    },
  },

  created() {
    swal.close();
    // this.showInProgressAlert();
  },

  methods: {
    getMappedInspectionFieldsModel() {
      const inspectionForm = this.inspection.inspectionForm;
      const inspectionFieldByFormField = {};
      for (const field of this.inspection.inspectionFields.filter(
        (item) => item.inspectionFormSectionField != null
      )) {
        inspectionFieldByFormField[field.inspectionFormSectionField.id] = field;
      }

      const inspectionModel = {};
      for (const section of inspectionForm.inspectionFormSections) {
        inspectionModel[section.id] = {};
        for (const subSection of section.subSections) {
          inspectionModel[section.id][subSection.id] = {};
          for (const field of subSection.inspectionFormSectionFields) {
            if (inspectionFieldByFormField[field.id]) {
              inspectionModel[section.id][subSection.id][field.id] =
                inspectionFieldByFormField[field.id];
            } else {
              inspectionModel[section.id][subSection.id][field.id] = {
                ...defaultInspectionField,
                inspectionFormSectionField: {
                  type: "inspection-form-section-fields",
                  id: field.id,
                },
              };
            }
          }
        }
      }
      return inspectionModel;
    },

    getSectionScore(section) {
      return this.inspection.sectionsScores[section.id].score;
    },

    inspectionUpdated(touchedSection) {
      if (this.inspection.status == INSPECTION_STATUS_IN_PROGRESS) {
        this.$emit("inspectionUpdated", touchedSection);
      }
    },

    openInspectionSection(section) {
      if (this.inspection.status == INSPECTION_STATUS_COMPLETED) {
        this.inspectionSectionModalOpened = true;
        this.openedSection = section;
        return;
      }
      const touchedSection = this.openedSection;
      this.inspectionSectionModalOpened = true;
      this.inspectionUpdated(touchedSection);
      this.delayedOpenedSection = section;
    },

    openNextInspectionSection() {
      const inspectionFormSections =
        this.inspection.inspectionForm.inspectionFormSections.filter((item) =>
          canShowElement(item, this.inspectionFieldsModel)
        );
      const openedSectionIndex = inspectionFormSections.findIndex(
        (item) => item.id == this.openedSection.id
      );
      const nextSection = inspectionFormSections[openedSectionIndex + 1];
      this.inspectionUpdated(this.openedSection);
      if (nextSection) {
        this.delayedOpenedSection = nextSection;
      } else {
        this.closeInspectionSection();
      }
    },

    onEndForm() {
      this.delayedOpenedSection = this.openedSection;
      this.inspectionUpdated(this.openedSection);
      this.closeInspectionSection();
      setTimeout(() => {
        this.openedSection = null;
      }, 10000);
    },

    openPrevInspectionSection() {
      const inspectionFormSections =
        this.inspection.inspectionForm.inspectionFormSections.filter((item) =>
          canShowElement(item, this.inspectionFieldsModel)
        );
      const openedSectionIndex = inspectionFormSections.findIndex(
        (item) => item.id == this.openedSection.id
      );
      const prevSection = inspectionFormSections[openedSectionIndex - 1];
      this.inspectionUpdated(this.openedSection);
      if (prevSection) {
        this.delayedOpenedSection = prevSection;
      } else {
        this.closeInspectionSection();
      }
    },

    closeInspectionSection() {
      this.openedSection = null;
      this.inspectionSectionModalOpened = false;
    },

    sectionChanged(sectionId, value) {
      this.inspectionFieldsModel = {
        ...cloneDeep(this.inspectionFieldsModel),
        [sectionId]: value,
      };
    },

    async startCompleteInspection() {
      /** show untouched fields */
      const untouchedFields = getUntouchedFields(
        this.inspection,
        this.inspectionFieldsModel
      );
      if (untouchedFields.length > 0) {
        this.showUntouchedFieldModal = true;
        return;
      }

      /** show reminders */
      if (!this.remindersValidated) {
        this.showRemindersModal = true;
        return;
      }

      this.showCommentAndSignModal = true;
    },

    async completeInspection(comment, signature) {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        await this.$store.dispatch("inspections/complete", {
          inspectionId: this.inspection.id,
          data: {
            comment,
            signature,
          },
        });
        this.$emit("inspectionUpdated");
        this.showCommentAndSignModal = false;
        this.showCompleteModal = true;
        swal.close();
      } catch (error) {
        swal.close();
        console.log(error);
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async downloadReport() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const response = await this.$store.dispatch(
          "inspections/getReport",
          this.inspection.id
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${this.inspection.code}-report.pdf`);
        document.body.appendChild(link);
        link.click();
        swal.close();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        swal.close();
      }
    },

    startInspection() {
      this.$emit("onStartInspection");
    },

    takeOverInspection() {
      this.$emit("onTakeOverInspection");
    },

    showInProgressAlert() {
      if (
        !!this.inspection &&
        this.inspection.status == INSPECTION_STATUS_IN_PROGRESS &&
        this.me.id != this.inspection.startedBy?.id
      ) {
        this.showAlertModal = true;
      } else {
        this.showAlertModal = false;
      }
    },

    async viewEstimate() {
      await this.$router.push(
        this.$objectViewRoute(this.inspection.estimation)
      );
    },

    onViewInspection() {
      this.$emit("onViewInspection", this.inspection);
    },
  },

  mounted() {},

  watch: {
    inspection(inspection) {
      this.inspectionFieldsModel = this.getMappedInspectionFieldsModel();
    },
    loading(loading) {
      if (!loading) {
        this.openedSection = this.delayedOpenedSection;
        this.delayedOpenedSection = null;
      }
    },
  },
};
</script>
