import {
  INSPECTION_FORM_FIELD_TYPE_STATE_WIDGET,
  INSPECTION_FORM_FIELD_TYPE_BATTERY_STATE_WIDGET,
  INSPECTION_FORM_FIELD_TYPE_TIRE_STATE_WIDGET,
  INSPECTION_FORM_FIELD_TYPE_DRUM_THICKNESS_WIDGET,
  INSPECTION_FORM_FIELD_TYPE_PAD_THICKNESS_WIDGET,
  INSPECTION_FORM_FIELD_TYPE_CHECK,
} from "../constants/inspectionForms";

export const getGoodFieldValue = (field) => {
  if (field.field_type === INSPECTION_FORM_FIELD_TYPE_STATE_WIDGET) {
    return "GOOD";
  }
  if (field.field_type === INSPECTION_FORM_FIELD_TYPE_BATTERY_STATE_WIDGET) {
    return { value: "GOOD", charge: 1, total_charge: 1 };
  }
  if (field.field_type === INSPECTION_FORM_FIELD_TYPE_TIRE_STATE_WIDGET) {
    return {
      value: "GOOD",
      ratio: 11,
      type: "STANDARD",
      season: "SUMMER",
    };
  }
  if (field.field_type === INSPECTION_FORM_FIELD_TYPE_DRUM_THICKNESS_WIDGET) {
    return {
      value: "GOOD",
      ratio: 10,
    };
  }
  if (field.field_type === INSPECTION_FORM_FIELD_TYPE_PAD_THICKNESS_WIDGET) {
    return {
      value: "GOOD",
      ratio: 10,
      type: "STANDARD",
    };
  }
  if (field.field_type === INSPECTION_FORM_FIELD_TYPE_CHECK) {
    const pointsThreshold = field.points_threshold;
    const option = pointsThreshold.reduce(
      (max, obj) => (obj.points > max.points ? obj : max),
      pointsThreshold[0]
    );
    return option.option;
  }
  const pointsThreshold = field.points_threshold;
  if (pointsThreshold && Array.isArray(pointsThreshold)) {
    const option = pointsThreshold.reduce(
      (max, obj) => (obj.points > max.points ? obj : max),
      pointsThreshold[0]
    );
    return option.option;
  }
  return null;
};

export const getFieldLitteralValue = (field) => {
  if (field.field_type === INSPECTION_FORM_FIELD_TYPE_TIRE_STATE_WIDGET) {
    return field.value ? field.value.value : "";
  }
  if (field.field_type === INSPECTION_FORM_FIELD_TYPE_BATTERY_STATE_WIDGET) {
    return field.value ? field.value.value : "";
  }
  if (field.field_type === INSPECTION_FORM_FIELD_TYPE_DRUM_THICKNESS_WIDGET) {
    return field.value ? field.value.value : "";
  }
  if (field.field_type === INSPECTION_FORM_FIELD_TYPE_PAD_THICKNESS_WIDGET) {
    return field.value ? field.value.value : "";
  }
  return field.value ? field.value : "";
};

export const getFieldValueById = (fieldId, inspectionFieldsModel) => {
  let field = null;

  for (const section of Object.values(inspectionFieldsModel)) {
    for (const subSection of Object.values(section)) {
      for (const subSectionField of Object.values(subSection)) {
        if (subSectionField.inspectionFormSectionField.id == fieldId) {
          field = subSectionField;
        }
      }
    }
  }

  if (field) {
    return getFieldLitteralValue(field);
  }
  return 0;
};

export const evaluateExpression = (expression, context) => {
  expression = expression.replace(/\band\b/g, "&&").replace(/\bor\b/g, "||");

  for (const key in context) {
    if (typeof context[key] === "string") {
      context[key] = context[key].toLowerCase();
    }
    const regex = new RegExp(`\\b${key}\\b`, "g");
    expression = expression.replace(regex, `context['${key}']`);
  }

  const func = new Function("context", `return ${expression};`);
  return func(context);
};

export const canShowElement = (element, inspectionFieldsModel) => {
  if (element.conditional_field) {
    const conditionExpressions = element.conditions
      .map(
        (item) =>
          `v${item.field} ${item.operator} '${item.value.toLowerCase()}'`
      )
      .join(element.condition_relation);
    const context = {};
    for (const condition of element.conditions) {
      context[`v${condition.field}`] = getFieldValueById(
        condition.field,
        inspectionFieldsModel
      ).toLowerCase();
    }
    return evaluateExpression(conditionExpressions, context);
  }
  return true;
};

export const isSectionTouched = (section, inspection) => {
  return inspection.touched_sections.includes(section.id);
};

export const isSectionFillComplete = (section, inspectionFieldsModel) => {
  if (!canShowElement(section, inspectionFieldsModel)) {
    return true;
  }
  for (const subSection of section.subSections) {
    if (!canShowElement(subSection, inspectionFieldsModel)) {
      continue;
    }
    for (const field of subSection.inspectionFormSectionFields) {
      if (!canShowElement(field, inspectionFieldsModel)) {
        continue;
      }
      const fieldData =
        inspectionFieldsModel[section.id][subSection.id][field.id];
      if (
        fieldData.inspectionFormSectionField.field_type ==
        INSPECTION_FORM_FIELD_TYPE_CHECK
      ) {
        continue;
      }
      if (!fieldData.touched) {
        return false;
      }
    }
  }
  return true;
};

export const getUntouchedFields = (inspection, inspectionFieldsModel) => {
  const untouchedFields = [];
  const inspectionFormSections =
    inspection.inspectionForm.inspectionFormSections;
  for (const section of inspectionFormSections) {
    if (!canShowElement(section, inspectionFieldsModel)) {
      continue;
    }
    for (const subSection of section.subSections) {
      if (!canShowElement(subSection, inspectionFieldsModel)) {
        continue;
      }
      for (const field of subSection.inspectionFormSectionFields) {
        if (!canShowElement(field, inspectionFieldsModel)) {
          continue;
        }
        if (field.field_type == INSPECTION_FORM_FIELD_TYPE_CHECK) {
          continue;
        }
        const fieldData =
          inspectionFieldsModel[section.id][subSection.id][field.id];
        if (!fieldData.touched) {
          untouchedFields.push(fieldData);
        }
      }
    }
  }

  return untouchedFields;
};
