<template>
  <div class="inspection-details-content-item">
    <div class="inspection-details-content-item-header">
      <div class="left">
        <h5>{{ subSectionForm.name }}</h5>
      </div>
    </div>
    <div class="inspection-details-content-item-list">
      <div
        class="item"
        v-for="field in subSectionForm.inspectionFormSectionFields.filter(
          (item) => canShowElement(item, inspectionData)
        )"
        :key="field.id"
      >
        <inspection-view-inspection-section-sub-section-field-view
          :fieldForm="field"
          :fieldData="subSectionData[field.id]"
          :disabled="disabled"
          :inspection="inspection"
          :settingLoading="settingLoading"
          @fieldChanged="
            (value) => {
              fieldChanged(field.id, value);
            }
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, isEqual } from "lodash";
import { Button } from "element-ui";
import { canShowElement } from "@/helpers/formFieldHelper";
import {
  INSPECTION_FORM_FIELD_TYPE_TIRE_STATE_WIDGET,
  INSPECTION_FORM_FIELD_TYPE_DRUM_THICKNESS_WIDGET,
  INSPECTION_FORM_FIELD_TYPE_PAD_THICKNESS_WIDGET,
} from "@/constants/inspectionForms";
import InspectionViewInspectionSectionSubSectionFieldView from "./InspectionViewInspectionSectionSubSectionFieldView.vue";

export default {
  name: "inspection-view-inspection-section-sub-section-view",

  components: {
    [Button.name]: Button,
    InspectionViewInspectionSectionSubSectionFieldView,
  },

  props: [
    "subSectionForm",
    "subSectionData",
    "inspectionData",
    "disabled",
    "inspection",
    "settingLoading",
  ],

  data() {
    return {
      subSectionModel: cloneDeep(this.subSectionData),
      canShowElement: canShowElement,
    };
  },

  computed: {},

  created() {},

  methods: {
    async fieldChanged(fieldId, fieldData) {
      const linkedFields = await this.getLinkedFields(fieldData);

      this.subSectionModel = {
        ...cloneDeep(this.subSectionModel),
        [fieldId]: fieldData,
        ...linkedFields,
      };
      this.$emit("subSectionChanged", cloneDeep(this.subSectionModel));
    },

    async getLinkedFields(fieldData) {
      const fieldType = fieldData.inspectionFormSectionField.field_type;
      const canBeLinked = [
        INSPECTION_FORM_FIELD_TYPE_TIRE_STATE_WIDGET,
        INSPECTION_FORM_FIELD_TYPE_DRUM_THICKNESS_WIDGET,
        INSPECTION_FORM_FIELD_TYPE_PAD_THICKNESS_WIDGET,
      ].includes(fieldType);

      if (!canBeLinked) {
        return {};
      }

      const linkedFields = {};
      for (const id in this.subSectionModel) {
        const field = this.subSectionModel[id];
        if (field.inspectionFormSectionField.field_type == fieldType) {
          linkedFields[id] = field;
        }
      }

      const updatedFields = {};
      const firstFieldId = Object.keys(linkedFields).sort((a, b) => {
        return (
          linkedFields[a].inspectionFormSectionField.order_number -
          linkedFields[b].inspectionFormSectionField.order_number
        );
      })[0];
      if (firstFieldId == fieldData.inspectionFormSectionField.id) {
        const fieldToChange = Object.values(linkedFields);
        fieldToChange.shift();
        // let areFieldsLinked = fieldToChange.every((obj) => {
        //   return _.isEqual(obj.value, fieldToChange[0].value);
        // });

        // if (!areFieldsLinked) {
        //   return {};
        // }

        for (const fData of fieldToChange) {
          const updateFieldData = {
            ...cloneDeep(fData),
            touched: true,
            action: fieldData.action,
            value: cloneDeep(fieldData.value),
          };
          updatedFields[fData.inspectionFormSectionField.id] = updateFieldData;

          this.updateField(updateFieldData);
        }
      }

      return updatedFields;
    },

    async updateField(fieldData) {
      try {
        await this.$store.dispatch("inspectionFields/update", {
          ...cloneDeep(fieldData),
          inspection: this.inspection,
        });
      } catch (error) {
        console.log(error);
      }
    },
  },

  mounted() {},

  watch: {
    subSectionData() {
      this.subSectionModel = cloneDeep(this.subSectionData);
    },
  },
};
</script>
