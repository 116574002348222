<template>
  <form
    class="inspection-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
  >
    <div
      class="inspection-form-group width-1"
      v-if="
        $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
        !hideOrganization
      "
    >
      <base-input
        :label="`${$t('COMMON.ORGANIZATION')} (*)`"
        :placeholder="$t('COMMON.ORGANIZATION')"
      >
        <organization-selector
          :organization="vehicle.organization?.id"
          :filterable="true"
          :showAll="false"
          :disabled="disableLocation || disable"
          @organizationChanged="
            (organizationId) => {
              reset();
              vehicle.organization.id = organizationId;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.organization" />
    </div>

    <div class="inspection-form-group width-1 gallery">
      <gallery-selector
        :label="$t('COMMON.PICTURES')"
        :defaultGallery="vehicle.gallery"
        ressource_name="vehicles"
        :ressource_id="$idExist(vehicle.id) ? vehicle.id : '0'"
        field="gallery"
        :disabled="disable"
        @galleryChanged="
          (gallery_urls) => {
            vehicle.gallery = gallery_urls;
            onFormChanged();
          }
        "
      />
    </div>
    <div class="inspection-form-title">
      <h3>{{ $t("VEHICLES.VEHICLE_IDENTIFICATION") }}</h3>
    </div>

    <div class="inspection-form-divider"></div>

    <div
      class="inspection-form-group width-1"
      v-if="
        $currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS) &&
        !hideOrganization
      "
    >
      <base-input
        :label="`${$t('COMMON.LOCATION')}`"
        :placeholder="$t('COMMON.LOCATION')"
      >
        <location-selector
          :location="vehicle?.allowedLocations[0]?.id ?? null"
          :filterable="true"
          :showAll="true"
          :multiple="false"
          :organization="vehicle.organization?.id"
          :disabled="disableLocation || disable"
          :options-value="locationOptions"
          default-first-option
          @locationChanged="
            (location) => {
              if (location) {
                vehicle.allowedLocations[0] = {
                  id: location,
                  type: 'locations',
                };
              } else {
                vehicle.allowedLocations = [];
              }
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.location" />
    </div>

    <div class="inspection-form-group width-1_2 has-button">
      <base-input
        :label="`${$t('VEHICLES.SKU_NUMBER')}`"
        :placeholder="$t('VEHICLES.SKU_NUMBER')"
      >
        <vehicle-by-sku-selector
          :filterOrganization="vehicle.organization?.id"
          :disabled="disable"
          :sku="vehicle.sku"
          @skuChanged="
            (sku) => {
              vehicle.sku = sku;
            }
          "
          @vehicleSelected="
            (vehicleData) => {
              vehicle.id = `${vehicleData.id}`;
              vehicle.vin = vehicleData.vin;
              vehicle.make = vehicleData.make;
              vehicle.model = vehicleData.model;
              vehicle.series = vehicleData.series;
              vehicle.model_year = vehicleData.model_year;
              vehicle.style = vehicleData.style;
              vehicle.engine_description = vehicleData.engine_description;
              vehicle.fuel_type = vehicleData.fuel_type;
              vehicle.transmission = vehicleData.transmission;
              vehicle.drivetrain = vehicleData.drivetrain;
              vehicle.class_code = vehicleData.class_code;
              vehicle.class_name = vehicleData.class_name;
              vehicle.gallery = vehicleData.gallery || [];
              vehicle.registration_number = vehicleData.registration_number;
              vehicle.odometer = vehicleData.odometer ?? 0;
              vehicle.color = vehicleData.color;
              vehicle.sku = vehicleData.sku;
              vehicle.fuel_type = vehicleData.fuel_type;

              hasInProgressInspections(vehicleData.inspection_ids);

              onFormChanged();
            }
          "
          clearable
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.sku" />
    </div>

    <div class="inspection-form-group width-1_2 has-button">
      <base-input
        :label="`${$t('VEHICLES.VIN')}`"
        :placeholder="$t('VEHICLES.VIN')"
      >
        <vehicle-api-by-vin-selector
          :filterOrganization="vehicle.organization?.id"
          :disabled="disable"
          :vin="vehicle.vin"
          @vinChanged="
            (vin) => {
              vehicle.vin = vin;
            }
          "
          @vehicleSelected="
            (vehicleData) => {
              vehicle.id = `${vehicleData.id}`;
              vehicle.vin = vehicleData.requested_vin;
              vehicle.make = vehicleData.make;
              vehicle.model = vehicleData.model;
              vehicle.series = vehicleData.series;
              vehicle.model_year = vehicleData.model_year;
              vehicle.style = vehicleData.style;
              vehicle.engine_description = vehicleData.engine_description;
              vehicle.fuel_type = vehicleData.fuel_type;
              vehicle.transmission = vehicleData.transmission;
              vehicle.drivetrain = vehicleData.drivetrain;
              vehicle.class_code = vehicleData.class_code;
              vehicle.class_name = vehicleData.class_name;
              vehicle.gallery = vehicleData.gallery || [];
              vehicle.registration_number = vehicleData.registration_number;
              vehicle.odometer = vehicleData.odometer ?? 0;
              vehicle.color = vehicleData.color;
              vehicle.sku = vehicleData.sku;
              vehicle.fuel_type = vehicleData.fuel_type;

              hasInProgressInspections(vehicleData.inspection_ids);

              hasInProgressEstimations(vehicleData.estimation_ids);

              hasInProgressRepairs(vehicleData.repair_ids);

              onFormChanged();
            }
          "
          clearable
        />
      </base-input>
    </div>

    <!-- <div class="inspection-form-group width-1_2">
      <base-input
        :label="`${$t('VEHICLES.VIN')} `"
        :placeholder="$t('VEHICLES.VIN')"
        v-model="vehicle.vin"
        :disabled="disable"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.vin" />
    </div> -->

    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.MODEL_YEAR')} (*)`"
        :placeholder="$t('VEHICLES.MODEL_YEAR')"
        :disabled="disable"
        @change="onFormChanged()"
      >
        <year-selector
          :disabled="disable"
          :filterable="true"
          :value="vehicle.model_year"
          @yearChanged="
            (year) => {
              vehicle.model_year = year;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.model_year" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.MAKE')} (*)`"
        :placeholder="$t('VEHICLES.MAKE')"
        :disabled="disable"
        @change="onFormChanged()"
      >
        <make-selector
          :year="vehicle.model_year"
          :disabled="disable"
          :filterable="true"
          :placeholder="$t('VEHICLES.MAKE')"
          :value="vehicle.make"
          @makeChanged="
            (make) => {
              vehicle.make = make;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.make" />
    </div>

    <div class="inspection-form-group width-1_2">
      <base-input
        :label="`${$t('VEHICLES.MODEL')} (*)`"
        :placeholder="$t('VEHICLES.MODEL')"
        :disabled="disable"
        @change="onFormChanged()"
      >
        <model-selector
          :year="vehicle.model_year"
          :disabled="disable"
          :filterable="true"
          :make="vehicle.make"
          :value="vehicle.model"
          @modelChanged="
            (model) => {
              vehicle.model = model;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.model" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.SERIES')}`"
        :placeholder="$t('VEHICLES.SERIES')"
        :disabled="disable"
      >
        <serie-selector
          :year="vehicle.model_year"
          :make="vehicle.make"
          :disabled="disable"
          :model="vehicle.model"
          :filterable="true"
          :value="vehicle.series"
          @serieChanged="
            (serie) => {
              vehicle.series = serie;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.series" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.STYLE')}`"
        :placeholder="$t('VEHICLES.STYLE')"
        :disabled="disable"
        @change="onFormChanged()"
      >
        <style-selector
          :year="vehicle.model_year"
          :make="vehicle.make"
          :model="vehicle.model"
          :serie="vehicle.serie"
          :disabled="disable"
          :filterable="true"
          :value="vehicle.style"
          @styleChanged="
            (style) => {
              vehicle.style = style;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.style" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.ENGINE_DESCRIPTION')} `"
        :placeholder="$t('VEHICLES.ENGINE_DESCRIPTION')"
        v-model="vehicle.engine_description"
        :disabled="disable"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.engine_description" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.FUEL_TYPE')} `"
        :placeholder="$t('VEHICLES.FUEL_TYPE')"
        v-model="vehicle.fuel_type"
        :disabled="disable"
        @change="onFormChanged()"
      >
        <fuel-type-selector
          :disabled="disable"
          :filterable="true"
          :value="vehicle.fuel_type"
          :options="fuelTypes ?? []"
          @fuelTypeChanged="
            (fuelType) => {
              vehicle.fuel_type = fuelType;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.fuel_type" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.CLASS_NAME')}`"
        :placeholder="$t('VEHICLES.CLASS_NAME')"
        :disabled="disable"
        @change="onFormChanged()"
      >
        <vehicle-class-code-selector
          :disabled="disable"
          :filterable="true"
          :value="vehicle.class_code"
          @classCodeChanged="
            (classCode) => {
              vehicle.class_code = classCode;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.class_code" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.REGISTRATION_NUMBER')} `"
        :placeholder="$t('VEHICLES.REGISTRATION_NUMBER')"
        v-model="vehicle.registration_number"
        :disabled="disable"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.registration_number" />
    </div>

    <!-- <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.CLASS_NAME')}`"
        :placeholder="$t('VEHICLES.CLASS_NAME')"
        v-model="vehicle.class_name"
        :disabled="disable"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.class_name" />
    </div> -->

    <div class="inspection-form-group width-1_4">
      <base-input
        :label="`${$t('VEHICLES.COLOR')} `"
        :placeholder="$t('VEHICLES.COLOR')"
        v-model="vehicle.color"
        :disabled="disable"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.color" />
    </div>

    <div class="inspection-form-group width-1_4" v-if="false">
      <base-input
        :label="`${$t('VEHICLES.TRANSMISSION')} `"
        :placeholder="$t('VEHICLES.TRANSMISSION')"
        :disabled="disable"
        @change="onFormChanged()"
      >
        <transmission-selector
          :disabled="disable"
          :filterable="true"
          :value="vehicle.transmission"
          @transmissionChanged="
            (transmission) => {
              vehicle.transmission = transmission;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.transmission" />
    </div>

    <div class="inspection-form-group width-1_4" v-if="false">
      <base-input
        :label="`${$t('VEHICLES.DRIVETRAIN')} `"
        :placeholder="$t('VEHICLES.DRIVETRAIN')"
        :disabled="disable"
        @change="onFormChanged()"
      >
        <drivetrain-selector
          :disabled="disable"
          :filterable="true"
          :value="vehicle.drivetrain"
          @drivetrainChanged="
            (drivetrain) => {
              vehicle.drivetrain = drivetrain;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.drivetrain" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        v-model="vehicle.odometer"
        :disabled="disable"
        :label="`${$t('VEHICLES.ODOMETER')} (*)`"
        :placeholder="$t('VEHICLES.ODOMETER')"
        @change="onFormChanged()"
        class="clear-input-number-arrows odometer"
        min="0"
        type="number"
      >
      </base-input>
      <validation-error :errors="apiValidationErrors.odometer" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        v-model="vehicle.odometer"
        :disabled="disable"
        :label="`${$t('VEHICLES.ODOMETER_UNIT')} (*)`"
        :placeholder="$t('VEHICLES.ODOMETER_UNIT')"
        @change="onFormChanged()"
      >
        <el-select
          v-model="vehicle.odometer_unit"
          :placeholder="$t('VEHICLES.ODOMETER_UNIT')"
          :disabled="disable"
          @change="onFormChanged()"
        >
          <el-option value="km" :label="$t('ODOMETERS.KM')" />
          <el-option value="miles" :label="$t('ODOMETERS.MILES')" />
        </el-select>
      </base-input>
      <validation-error :errors="apiValidationErrors.odometer_unit" />
    </div>

    <div
      class="inspection-form-group width-1"
      v-if="
        false &&
        $currentUserCan($permissions.PERM_VIEW_ANY_CUSTOMERS) &&
        showCustomer
      "
    >
      <base-input
        :label="`${$t('COMMON.CUSTOMERS')}`"
        :placeholder="$t('COMMON.CUSTOMERS')"
      >
        <customer-selector
          :allowNone="true"
          :customer="vehicle.customer?.id"
          :filterable="true"
          :showAll="false"
          :filterOrganization="vehicle.organization?.id"
          @customerChanged="
            (customerId, customer) => {
              vehicle.customer = customer;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.customer" />
    </div>

    <div
      class="inspection-form-group width-1"
      v-if="
        false &&
        $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
        showMecanic
      "
    >
      <base-input
        :label="`${$t('COMMON.MECANIC')}`"
        :placeholder="$t('COMMON.MECANIC')"
      >
        <user-selector
          :allowNone="true"
          :user="vehicle.mecanic?.id"
          :filterable="true"
          :showAll="false"
          :filterOrganization="vehicle.organization?.id"
          @userChanged="
            (userId, user) => {
              vehicle.mecanic = user;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.user" />
    </div>

    <slot
      name="submit-button"
      v-bind:loading="loading"
      v-bind:disable="disable"
    >
      <div class="inspection-form-group width-1 next-button">
        <base-button
          type="button"
          class="btn btn-sm elite-submit"
          native-type="submit"
          :disabled="loading || disable"
        >
          <i class="fas fa-spinner fa-spin" v-if="loading"></i>
          {{ saveButtonText }}
        </base-button>
      </div>
    </slot>
  </form>
</template>
<script>
import _, { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import models from "@/constants/models";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationSelector from "@/components/LocationSelector.vue";
import VehicleApiByVinSelector from "@/components/VehicleApiByVinSelector.vue";
import VehicleBySkuSelector from "@/components/VehicleBySkuSelector.vue";
import GallerySelector from "@/components/GallerySelector.vue";
import MakeSelector from "@/components/MakeSelector.vue";
import YearSelector from "@/components/YearSelector.vue";
import ModelSelector from "@/components/ModelSelector.vue";
import SerieSelector from "@/components/SerieSelector.vue";
import StyleSelector from "@/components/StyleSelector.vue";
import FuelTypeSelector from "@/components/FuelTypeSelector.vue";
import TransmissionSelector from "@/components/TransmissionSelector.vue";
import DrivetrainSelector from "@/components/DrivetrainSelector.vue";
import VehicleClassCodeSelector from "@/components/VehicleClassCodeSelector.vue";
import CustomerSelector from "@/components/CustomerSelector.vue";
import UserSelector from "@/components/UserSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    LocationSelector,
    VehicleApiByVinSelector,
    GallerySelector,
    FuelTypeSelector,
    MakeSelector,
    YearSelector,
    ModelSelector,
    SerieSelector,
    StyleSelector,
    TransmissionSelector,
    DrivetrainSelector,
    VehicleClassCodeSelector,
    VehicleBySkuSelector,
    CustomerSelector,
    UserSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: {
    vehicleData: { type: Object },
    formErrors: { type: [Object, Array], default: null },
    loading: { type: Boolean, default: false },
    hideOrganization: { type: Boolean, default: false },
    disable: { type: Boolean, default: false },
    disableLocation: { type: Boolean, default: false },
    saveText: { type: String, default: null },
    fuelTypes: { type: [Array, undefined, null], default: null },
    showCustomer: { type: Boolean, default: false },
    showMecanic: { type: Boolean, default: false },
    location: { type: String | Number, default: false },
    locationOptions: { type: Array, default: null },
    organization: { type: String | Number, default: false },
  },

  data() {
    let vehicleData = cloneDeep(this.vehicleData);
    if (this.location) {
      vehicleData.allowedLocations = [{ id: this.location, type: "locations" }];
    }

    if (this.organization) {
      vehicleData.organization = {
        id: this.organization,
        type: "organizations",
      };
    }

    vehicleData = this.$fillUserOrganizationData(vehicleData);
    return {
      vehicle: vehicleData,
      permissions: [],
      permissionsModel: {},
      models: models,
      console: console,
      vehicleBackup: vehicleData,
    };
  },

  computed: {
    saveButtonText() {
      if (this.saveText) {
        return this.saveText;
      }
      if (this.$idExist(this.vehicle?.id)) {
        return this.$t("VEHICLES.EDIT_VEHICLE");
      } else {
        return this.$t("VEHICLES.ADD_VEHICLE");
      }
    },
  },

  created() {},

  methods: {
    async handleSubmit() {
      let vehicleData = cloneDeep(this.vehicle);
      vehicleData = this.$fillUserOrganizationData(vehicleData);
      if (vehicleData.customer) {
        if (!vehicleData.customer.id) {
          delete vehicleData.customer;
        }
      }
      if (vehicleData.mecanic) {
        if (!vehicleData.mecanic.id) {
          delete vehicleData.mecanic;
        }
      }
      this.$emit("vehicleSubmitted", vehicleData);
    },

    reset() {
      this.vehicle = { ...this.vehicle, ...this.vehicleBackup };
    },

    onFormChanged() {
      this.$emit("formChanged", this.vehicle);
    },

    hasInProgressInspections(inspectionIds = null) {
      if (
        !inspectionIds ||
        typeof inspectionIds !== "object" ||
        Object.keys(inspectionIds).length <= 0
      ) {
        return;
      }

      this.$emit("vehicleHasInProgressInspections", inspectionIds);
    },

    hasInProgressEstimations(estimationsIds = null) {
      if (
        !estimationsIds ||
        typeof estimationsIds !== "object" ||
        Object.keys(estimationsIds).length <= 0
      ) {
        return;
      }

      this.$emit("vehicleHasInProgressEstimations", estimationsIds);
    },

    hasInProgressRepairs(repairsIds = null) {
      if (
        !repairsIds ||
        typeof repairsIds !== "object" ||
        Object.keys(repairsIds).length <= 0
      ) {
        return;
      }

      this.$emit("vehicleHasInProgressRepairs", repairsIds);
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    vehicleData(vehicleData) {
      if (vehicleData) {
        this.vehicle = {
          ...this.vehicle,
          ...cloneDeep(vehicleData),
        };
      }
    },
    vehicle: {
      handler: function (value) {
        if (_.isEqual(value, this.vehicle)) return;

        this.$emit("formChanged", this.vehicle);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.input-group-text-p-0 {
  .input-group-text {
    padding: 0 !important;
  }
}
.input-group-text-border-left-0 {
  .input-group-text {
    border-left: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
.odometer {
  .border-right-0 {
    border-right: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.clear-input-number-arrows {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield !important;
  }
}
</style>
