<template>
  <div class="slider-group">
    <base-checkbox
      v-model="toReplace"
      :disabled="disabled"
      @input="toReplaceChecked"
    >
      {{ $t("INSPECTIONS.TO_REPLACE") }}
    </base-checkbox>
    <div class="modal-radio-group">
      <h5>
        {{ $t("INSPECTIONS.TIRE_TYPE") }}
      </h5>
      <div
        class="radio-group"
        v-for="type in types"
        :key="`type_${type}_${refKey}`"
      >
        <div class="btn-group-item">
          <input
            type="radio"
            class="btn-check"
            :id="`type_${type}_${refKey}`"
            :name="`type_${type}_${refKey}`"
            :disabled="disabled"
            :value="type"
            v-model="fieldValue.type"
          />
          <label
            class="btn btn-outline-primary"
            :for="`type_${type}_${refKey}`"
          >
            {{ $t(`INSPECTIONS.${type}`) }}
            <span class="check">
              <i class="far fa-check"></i>
            </span>
          </label>
        </div>
      </div>
    </div>
    <div class="slider-icon" :class="iconClass">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        viewBox="0 0 50 50"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_1929"
              data-name="Rectangle 1929"
              width="50"
              height="50"
              fill="none"
            />
          </clipPath>
        </defs>
        <g
          class="brake"
          id="Groupe_3102"
          data-name="Groupe 3102"
          transform="translate(0 0)"
        >
          <g
            class="back"
            id="Groupe_3099"
            data-name="Groupe 3099"
            transform="translate(0 0)"
          >
            <g
              id="Groupe_3098"
              data-name="Groupe 3098"
              clip-path="url(#clip-path)"
            >
              <path
                id="Tracé_2046"
                data-name="Tracé 2046"
                d="M50,25A25,25,0,1,1,25,0,25,25,0,0,1,50,25"
                transform="translate(0 0)"
                fill="#ffeb00"
              />
            </g>
          </g>
          <g
            id="Groupe_3101"
            data-name="Groupe 3101"
            transform="translate(0 0)"
          >
            <g
              id="Groupe_3100"
              data-name="Groupe 3100"
              clip-path="url(#clip-path)"
            >
              <path
                id="Tracé_2047"
                data-name="Tracé 2047"
                d="M12.656,20.164a8.841,8.841,0,1,0,8.784-8.894,8.84,8.84,0,0,0-8.784,8.894m5.775-.035a3.066,3.066,0,1,1,3.085,3.045,3.066,3.066,0,0,1-3.085-3.045m-4.072.024a1.282,1.282,0,1,1,1.29,1.274,1.283,1.283,0,0,1-1.29-1.274M19.623,27a1.282,1.282,0,1,1,1.574-.9,1.28,1.28,0,0,1-1.574.9m7.422-2.4a1.282,1.282,0,1,1-.19-1.8,1.284,1.284,0,0,1,.19,1.8m-.053-9.044a1.281,1.281,0,1,1-1.8-.17,1.28,1.28,0,0,1,1.8.17m-7.444-2.313a1.281,1.281,0,1,1-.884,1.583,1.281,1.281,0,0,1,.884-1.583M10.572,5.091l.635-.461L16,11.223l-.637.463a10.415,10.415,0,0,0-3.336,12.771l.329.717-7.408,3.4-.329-.712A18.574,18.574,0,0,1,10.572,5.091M8.193,29.082l1.894-.871a13.992,13.992,0,1,0,5.42-20.752L14.283,5.775a16.047,16.047,0,1,1-6.09,23.308"
                transform="translate(3.524 4.89)"
                fill-rule="evenodd"
              />
            </g>
          </g>
        </g>
      </svg>
      <base-slider
        v-model="fieldValue.ratio"
        :range="{ min: 0, max: max_value }"
        :options="{ step: 1 }"
        :disabled="disabled"
      ></base-slider>
      <span class="range-slider-value">
        {{ Math.round(fieldValue.ratio) }} / {{ max_value }} mm
      </span>
    </div>

    <action-comment-widget
      :showActionModal="showActionModal"
      :disabled="disabled"
      :actionValue="defaultActionValue"
      :fieldValue="fieldValue.value"
      :commentValue="commentValue"
      @actionCanceled="cancelAction"
      @actionChanged="changeAction"
    />
  </div>
</template>

<script>
import { cloneDeep, isEqual, debounce } from "lodash";
import { Button } from "element-ui";
import { uniqueId } from "lodash";
import { BaseSlider } from "@/components";
import {
  INSPECTION_ACTION_NO_ACTION,
  INSPECTION_ACTION_TO_REPLACE,
  INSPECTION_ACTION_TO_VERIFY,
} from "@/constants/inspections";
import ActionCommentWidget from "./ActionCommentWidget.vue";

export default {
  name: "pad-thickness-field-widget",

  components: {
    [Button.name]: Button,
    BaseSlider,
    ActionCommentWidget,
  },

  props: [
    "fieldForm",
    "defaultValue",
    "disabled",
    "defaultActionValue",
    "commentValue",
    "touched",
  ],

  data() {
    const refKey = uniqueId([]);
    const value = this.getFormattedValue();
    let maxValue = 10;
    if (value == "NOT_STANDARD") {
      maxValue = 12;
    }
    return {
      refKey,
      fieldValue: value,
      types: ["STANDARD", "NOT_STANDARD"],
      max_value: maxValue,
      warning_threshold: 3,
      good_threshold: 3,
      toReplace: this.defaultActionValue == INSPECTION_ACTION_TO_REPLACE,
      showActionModal: false,
    };
  },

  computed: {
    iconClass() {
      if (this.fieldValue.value === "GOOD") {
        return "icon-good";
      } else if (this.fieldValue.value === "WARNING") {
        return "icon-warning";
      } else {
        return "icon-bad";
      }
    },
  },

  created() {},

  methods: {
    getFormattedValue() {
      let value = cloneDeep(this.defaultValue);
      if (!value || typeof value != "object") {
        value = {
          value: "BAD",
          ratio: 0,
          type: null,
        };
        this.$emit("change", value);
      }
      return { ...value };
    },

    changeAction(action, comment) {
      this.showActionModal = false;
      this.$emit("change", this.fieldValue);
      this.$emit("actionChanged", action, comment);
    },

    cancelAction() {
      this.updateValue();
      this.showActionModal = false;
    },

    updateValueDebounced: debounce(function () {
      this.updateValue();
    }, 500),

    setMinMaxValues() {
      if (this.fieldValue.type == "STANDARD") {
        this.max_value = 10;
        this.warning_threshold = 3;
        this.good_threshold = 5;
      } else if (this.fieldValue.type == "NOT_STANDARD") {
        this.max_value = 12;
        this.warning_threshold = 4;
        this.good_threshold = 7;
      }
    },

    updateValue() {
      this.setMinMaxValues();

      if (this.fieldValue.ratio > this.max_value) {
        this.fieldValue.ratio = this.max_value;
      }

      if (this.fieldValue.ratio >= this.good_threshold) {
        this.fieldValue.value = "GOOD";
      } else if (this.fieldValue.ratio >= this.warning_threshold) {
        this.fieldValue.value = "WARNING";
      } else {
        this.fieldValue.value = "BAD";
      }

      if (this.fieldValue.value == "GOOD") {
        this.toReplace = false;
        this.$emit("actionChanged", INSPECTION_ACTION_NO_ACTION);
      } else if (this.fieldValue.value == "WARNING") {
        this.toReplace = false;
        this.$emit("actionChanged", INSPECTION_ACTION_TO_VERIFY);
      } else if (this.fieldValue.value == "BAD") {
        if (this.touched) {
          this.toReplace = true;
        }
        this.$emit("actionChanged", INSPECTION_ACTION_TO_REPLACE);
      }
      this.$emit("change", this.fieldValue);
    },

    toReplaceChecked(value) {
      if (value) {
        this.showActionModal = true;
        this.$emit("actionChanged", INSPECTION_ACTION_TO_REPLACE);
      } else {
        this.$emit("actionChanged", INSPECTION_ACTION_NO_ACTION);
      }
    },
  },

  mounted() {},

  watch: {
    defaultValue() {
      this.fieldValue = this.getFormattedValue();
      this.setMinMaxValues();
    },
    defaultActionValue() {
      this.toReplace = this.defaultActionValue == INSPECTION_ACTION_TO_REPLACE;
    },
    fieldValue: {
      handler: function () {
        if (!isEqual(this.fieldValue, this.defaultValue)) {
          this.updateValueDebounced();
        }
      },
      deep: true,
      immediate: false,
    },
  },
};
</script>
