<template>
  <view-object-ref-widget
    :image="inspection.vehicle.gallery[0] ?? null"
    :title="inspection.code"
  >
    <template #buttons>
      <button v-if="canCancel" @click="$emit('cancel')" class="delete">
        <span> {{ $t("COMMON.CANCEL") }} </span>
        <i class="far fa-trash-alt"></i>
      </button>
      <button v-if="canEdit" @click="$emit('edit')" class="edit">
        <span> {{ $t("COMMON.UPDATE") }} </span>
        <i class="far fa-edit"></i>
      </button>
      <button v-if="canShare" @click="$emit('share')" class="edit">
        <span> {{ $t("COMMON.SHARE") }} </span>
        <i class="fal fa-share"></i>
      </button>
      <button v-if="canView" @click="view" class="edit">
        <span> {{ $t("INSPECTIONS.SHOW_INSPECTION") }} </span>
        <i class="fa-light fa-eye"></i>
      </button>
      <button v-if="canRedo" @click="$emit('redo')" class="delete">
        <span> {{ $t("INSPECTIONS.REDO_INSPECTION") }} </span>
        <i class="far fa-edit"></i>
      </button>
    </template>
  </view-object-ref-widget>
</template>

<script>
import ViewObjectRefWidget from "./ViewObjectRefWidget.vue";
import { QUERY_ACTIONS_EDIT } from "@/constants/common";

export default {
  name: "inspection-ref-standardized",

  components: { ViewObjectRefWidget },

  props: {
    inspection: {
      type: Object,
    },
    canCancel: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canShare: {
      type: Boolean,
      default: false,
    },
    canView: {
      type: Boolean,
      default: false,
    },
    canRedo: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      QUERY_ACTIONS_EDIT,
    };
  },

  computed: {},

  methods: {
    view() {
      this.$emit("onView");
      if (this.$route.name != "List Inspections") {
        this.$router.push({
          name: "List Inspections",
          query: { id: this.inspection.id, action: QUERY_ACTIONS_EDIT },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
