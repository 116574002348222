<template>
  <div class="validation">
    <!-- <div class="validation-photo overflow-hidden">
      <img
        v-if="inspection.vehicle?.gallery?.length > 0"
        :src="inspection.vehicle?.gallery[0]"
        class="avatar rounded-circle mr-3"
      />
      <span
        class="default-icon d-flex h-100 w-100 justify-content-center align-items-center bg-lighter"
        v-else
      >
        <i class="far fa-camera"></i>
      </span>
    </div> -->
    <inspection-view-global :inspection="inspection" :show-buttons="false" />
  </div>
</template>

<script>
import InspectionViewGlobal from "./InspectionViewGlobal.vue";

export default {
  name: "inspection-form-validation-step",

  components: { InspectionViewGlobal },

  mixins: [],

  props: {
    inspectionData: { type: Object },
  },

  data() {
    const inspection = { ...this.inspectionData };
    return {
      inspection,
    };
  },

  watch: {
    inspectionData: {
      handler: function (value) {
        this.inspection = { ...this.inspection, ...value };
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
