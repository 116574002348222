<template>
  <div class="container-fluid inspection-global">
    <div class="page-title">
      <i class="fa-regular fa-briefcase"></i>
      <h1>{{ $t("COMMON.INSPECTIONS") }}</h1>
    </div>

    <div class="list-table">
      <inspection-list-table
        :key="renderKey * 100"
        :filterAssignedTo="selectedInspectionAssignedTo"
        :filterAssignedToGroup="selectedInspectionAssignedToGroup"
        :filterCreator="selectedInspectionCreator"
        :filterStatus="selectedInspectionStatus"
        @onAddInspection="openInspectionCreateModal"
        @onDeleteInspection="deleteInspection"
        @onEditInspection="openInspectionEditModal"
        @onShareInspection="onShareInspection"
        @onViewInspection="openInspectionViewModal"
        show-in-progresses
        show-tabs
      />
    </div>

    <transition name="slide-right">
      <div
        v-if="isViewInspectionModalOpened"
        class="resizable-wrapper no-line"
        v-bind:class="[isViewInspectionModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'INSPECTION'"
          @onCloseModal="closeInspectionModal(true, true)"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("INSPECTIONS.VIEW_INSPECTION") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openInspection"
                :objectType="'inspections'"
                :objectId="openInspection.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
                v-if="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="edit"
                  @click="openInspectionEditModal(openInspection)"
                  v-if="canEditInspection(openInspection)"
                >
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button
                  class="delete"
                  @click="deleteInspection(openInspection)"
                  v-if="canDeleteInspection(openInspection)"
                >
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
                <base-button
                  v-if="canApproveInspection(openInspection)"
                  type="info"
                  icon
                  size="sm"
                  @click="approveInspection(openInspection)"
                  :title="$t('INSPECTIONS.APPROVE_INSPECTION')"
                >
                  <span>
                    {{ $t("INSPECTIONS.APPROVE_INSPECTION") }}
                  </span>
                </base-button>

                <base-button
                  v-if="canTakeOverInspection(openInspection)"
                  type="primary"
                  icon
                  size="sm"
                  @click="takeOverInspection(openInspection)"
                  :title="$t('INSPECTIONS.TAKE_OVER_INSPECTION')"
                >
                  <span class="text-nowrap">
                    {{ $t("INSPECTIONS.TAKE_OVER_INSPECTION") }}
                  </span>
                </base-button>

                <base-button
                  v-if="canStartInspection(openInspection)"
                  type="primary"
                  icon
                  size="sm"
                  @click="startInspection(openInspection)"
                  :title="$t('INSPECTIONS.START_INSPECTION')"
                >
                  <span>
                    {{ $t("INSPECTIONS.START_INSPECTION") }}
                  </span>
                </base-button>

                <base-button
                  v-if="canAssignInspection(openInspection)"
                  type="primary"
                  icon
                  size="sm"
                  @click="
                    () => {
                      showAssignInspectionModal = true;
                    }
                  "
                  :title="$t('INSPECTIONS.ASSIGN_INSPECTION')"
                >
                  <span>
                    {{ $t("INSPECTIONS.ASSIGN_INSPECTION") }}
                  </span>
                </base-button>

                <base-button
                  v-if="canCompleteRequest(openInspection)"
                  type="success"
                  icon
                  size="sm"
                  @click="completeRequest(openInspection)"
                  :title="$t('INSPECTIONS.CLOSE_INSPECTION_REQUEST')"
                >
                  <span>
                    <!-- <i class="fa fa-check"></i>
                  <i class="fa fa-ticket-alt"></i> -->
                    {{ $t("INSPECTIONS.CLOSE_INSPECTION_REQUEST") }}
                  </span>
                </base-button>

                <base-button
                  v-if="!!openInspection.request"
                  type="info"
                  icon
                  size="sm"
                  @click="showRequest(openInspection)"
                  :title="$t('INSPECTIONS.SHOW_REQUEST')"
                >
                  <span>
                    {{ $t("INSPECTIONS.SHOW_REQUEST") }}
                  </span>
                </base-button>

                <base-button
                  v-if="canCancelInspection(openInspection)"
                  type="warning"
                  icon
                  size="sm"
                  @click="cancelInspection(openInspection)"
                  :title="$t('INSPECTIONS.CANCEL_INSPECTION')"
                >
                  <span>
                    {{ $t("INSPECTIONS.CANCEL_INSPECTION") }}
                  </span>
                </base-button>
              </base-dropdown>
              <button class="btn expand">
                <i class="fal fa-expand-arrows"></i>
              </button>
              <button class="btn close" @click="closeInspectionModal(true)">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-inspection-component
              v-if="openInspection"
              :inspectionId="openInspection.id"
              @onEditInspection="openInspectionEditModal"
              @onCancelInspection="cancelInspection"
              @onStartInspection="startInspection"
              @onTakeOverInspection="takeOverInspection"
              @onShareInspection="onShareInspection"
              @onViewInspection="
                (inspection) => openInspectionViewModal(inspection, true)
              "
              :loading="loading"
              @inspectionLoaded="
                (inspection) => {
                  openInspection = inspection;
                  openInspectionLoaded = true;
                }
              "
            />
          </div>
        </div>
      </div>
    </transition>

    <transition name="slide-right">
      <div
        v-if="isEditInspectionModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditInspectionModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'INSPECTION'"
          @onCloseModal="closeInspectionModal(true, true)"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("INSPECTIONS.EDIT_INSPECTION") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
                v-if="false"
              >
                <template slot="title">
                  <img
                    src="../../../../../public/img/sm-dots-vertical.svg"
                    alt="icon"
                  />
                </template>
                <button
                  class="view"
                  v-if="canViewInspection(openInspection)"
                  @click="openInspectionViewModal(openInspection)"
                >
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="btn expand">
                <i class="fal fa-expand-arrows"></i>
              </button>
              <button class="btn close" @click="closeInspectionModal(true)">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-inspection-component
              v-if="openInspection"
              :inspectionId="openInspection.id"
              @onViewInspection="openInspectionViewModal"
              @onCloseInspectionModal="closeInspectionModal"
            />
          </div>
        </div>
      </div>
    </transition>

    <transition name="slide-right">
      <div
        v-if="isAddInspectionModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddInspectionModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'INSPECTION'"
          @onCloseModal="closeInspectionModal(true, true)"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>{{ $t("INSPECTIONS.ADD_INSPECTION") }}</h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="btn expand">
                <i class="fal fa-expand-arrows"></i>
              </button>
              <button class="btn close" @click="closeInspectionModal(true)">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-inspection-component
              @onViewInspection="openInspectionViewModal"
              @onEditInspection="openInspectionEditModal"
              @onCloseInspectionModal="closeInspectionModal"
            />
          </div>
        </div>
      </div>
    </transition>

    <share-modal
      :open.sync="showShareInspectionModal"
      :emailValue="shareEmail"
      modal-classes="modal-secondary validate"
      :formErrors="formErrors"
      :loading="loading"
      @submit="shareInspection"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  INSPECTION_STATUS_DRAFT,
  INSPECTION_STATUS_APPROVED,
  INSPECTION_STATUS_ASSIGNED,
  INSPECTION_STATUS_COMPLETED,
  INSPECTION_STATUS_CANCELED,
  INSPECTION_STATUS_PENDING,
  INSPECTION_STATUS_IN_PROGRESS,
} from "@/constants/inspections";
import {
  REQUEST_STATUS_DRAFT,
  REQUEST_STATUS_ASSIGNED,
  REQUEST_STATUS_COMPLETED,
} from "@/constants/requests";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import InspectionListTable from "./partials/InspectionListTable.vue";
import EditInspectionComponent from "./components/EditInspectionComponent.vue";
import AddInspectionComponent from "./components/AddInspectionComponent.vue";
import ViewInspectionComponent from "./components/ViewInspectionComponent.vue";
import inspectionsService from "@/store/services/inspections-service";
import ShareModal from "@/components/ShareModal.vue";
import { cloneDeep } from "lodash";

export default {
  layout: "DashboardLayout",

  components: {
    InspectionListTable,
    NotificationSubscription,
    EditInspectionComponent,
    AddInspectionComponent,
    ViewInspectionComponent,
    ShareModal,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  data() {
    const inspectionId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewInspectionModalOpened = false;
    let isEditInspectionModalOpened = false;
    let isAddInspectionModalOpened = false;
    let openInspection = null;
    if (inspectionId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewInspectionModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditInspectionModalOpened = true;
      }
      openInspection = { id: inspectionId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddInspectionModalOpened = true;
    }
    return {
      query: null,
      isViewInspectionModalOpened: isViewInspectionModalOpened,
      isEditInspectionModalOpened: isEditInspectionModalOpened,
      isAddInspectionModalOpened: isAddInspectionModalOpened,
      openInspection: openInspection,
      openInspectionLoaded: false,
      showAssignInspectionModal: false,
      renderKey: 1,
      REQUEST_STATUS_DRAFT,
      REQUEST_STATUS_ASSIGNED,
      REQUEST_STATUS_COMPLETED,
      INSPECTION_STATUS_DRAFT,
      INSPECTION_STATUS_ASSIGNED,
      INSPECTION_STATUS_COMPLETED,
      INSPECTION_STATUS_CANCELED,
      INSPECTION_STATUS_IN_PROGRESS,
      INSPECTION_STATUS_PENDING,
      selectedInspectionStatus: null,
      selectedInspectionAssignedTo: null,
      selectedInspectionAssignedToGroup: false,
      selectedInspectionCreator: null,
      loading: false,
      showShareInspectionModal: false,
      shareEmail: null,
      formErrors: null,
      counters: {
        all: 0,
        draft: 0,
        pending: 0,
        assigned: 0,
        inProgress: 0,
        canceled: 0,
        assignedToMe: 0,
        completed: 0,
        todo: 0,
        createdByMe: 0,
      },
    };
  },

  computed: {
    ...mapGetters("profile", { me: "me" }),
  },

  watch: {},

  created() {
    // this.allInspections();
  },

  methods: {
    openInspectionCreateModal() {
      this.closeInspectionModal();
      this.isAddInspectionModalOpened = true;
      this.openInspectionLoaded = false;

      this.$router.replace({
        name: "List Inspections",
        query: { action: QUERY_ACTIONS_ADD },
      });
    },

    openInspectionViewModal(inspection, reRender = false) {
      if (
        [INSPECTION_STATUS_DRAFT, INSPECTION_STATUS_PENDING].includes(
          inspection?.status
        )
      ) {
        this.openInspectionEditModal(inspection);
        return;
      }

      this.closeInspectionModal();
      this.openInspection = inspection;
      this.isViewInspectionModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      this.$router.replace({
        name: "List Inspections",
        query: { id: this.openInspection.id, action: QUERY_ACTIONS_VIEW },
      });
    },

    openInspectionEditModal(inspection) {
      this.closeInspectionModal();
      this.openInspection = inspection;
      this.isEditInspectionModalOpened = true;

      this.$router.replace({
        name: "List Inspections",
        query: { id: this.openInspection.id, action: QUERY_ACTIONS_EDIT },
      });
    },

    closeInspectionModal(goBack = false, grid = false) {
      if (goBack && this.$store.getters["routes/previousPathRoute"]) {
        if (grid) {
          this.$store.dispatch("routes/goBackToPreviousGrid");
        } else {
          this.$store.dispatch("routes/goToPreviousPath");
        }
        return;
      }

      if (
        this.isAddInspectionModalOpened ||
        this.isViewInspectionModalOpened ||
        this.isEditInspectionModalOpened
      ) {
        this.renderKey++;
      }
      this.isAddInspectionModalOpened = false;
      this.isViewInspectionModalOpened = false;
      this.isEditInspectionModalOpened = false;
      this.openInspectionLoaded = false;
      this.openInspection = null;

      if (
        this.$route.name !== "List Inspections" ||
        JSON.stringify(this.$route.query) !== JSON.stringify({})
      ) {
        this.$router
          .replace({
            name: "List Inspections",
            query: {},
          })
          .catch((err) => {
            if (err.name !== "NavigationDuplicated") {
              throw err;
            }
          });
      }
    },

    onShareInspection(inspection) {
      this.openInspection = cloneDeep(inspection);
      this.shareEmail = inspection?.customer?.email;

      this.$nextTick(function () {
        this.showShareInspectionModal = true;
      });
    },

    async deleteInspection(inspection) {
      const confirmation = await swal.fire({
        title: this.$t("INSPECTIONS.DELETE_THIS_INSPECTION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.isConfirmed) {
          await this.$store.dispatch("inspections/destroy", inspection.id);
          this.renderKey++;
          this.closeInspectionModal();
          this.$notify({
            type: "success",
            timeout: 3000,
            message: this.$t("INSPECTIONS.INSPECTION_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async showRequest(inspection) {
      this.$router.push({
        name: "List Requests",
        query: { id: inspection.request.id, action: QUERY_ACTIONS_VIEW },
      });
    },

    async approveInspection(inspection) {
      const confirmation = await swal.fire({
        title: this.$t("INSPECTIONS.APPROVE_INSPECTION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (confirmation.isDismissed) {
        return;
      }

      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        await this.$store.dispatch("inspections/approve", {
          inspectionId: inspection.id,
          data: {},
        });
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("INSPECTIONS.INSPECTION_APPROVED"),
        });
        this.renderKey++;
        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async startInspection(inspection) {
      this.loading = true;
      swal.showLoading();

      try {
        await this.$store.dispatch("inspections/start", {
          inspectionId: inspection.id,
          data: {},
        });
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("INSPECTIONS.INSPECTION_STARTED"),
        });
        this.renderKey++;
        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    async takeOverInspection(inspection) {
      this.loading = true;
      swal.showLoading();

      try {
        await this.$store.dispatch("inspections/takeOver", {
          inspectionId: inspection.id,
          data: {},
        });
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("INSPECTIONS.INSPECTION_TOOK_OVER"),
        });
        this.renderKey++;
        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    async cancelInspection(inspection) {
      swal.showLoading();
      this.loading = true;
      try {
        await this.$store.dispatch("inspections/cancel", inspection.id);
        this.renderKey++;
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("INSPECTIONS.INSPECTION_CANCELED"),
        });
        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    async shareInspection(email) {
      swal.showLoading();
      this.loading = true;
      this.formErrors = null;

      try {
        await this.$store.dispatch("inspections/share", {
          inspectionId: this.openInspection.id,
          data: {
            email,
          },
        });
        this.renderKey++;
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("INSPECTIONS.SHARED"),
        });
        swal.close();
        this.showShareInspectionModal = false;
        // this.openInspection = null;
      } catch (error) {
        swal.close();
        await this.showRequestError(error, false);
        this.formErrors = error.response?.data?.errors;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    async completeRequest(inspection) {
      const confirmation = await swal.fire({
        title: this.$t("INSPECTIONS.CLOSE_INSPECTION_REQUEST"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (confirmation.isDismissed) {
        return;
      }

      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        await this.$store.dispatch("requests/complete", {
          requestId: inspection.request.id,
          data: { inspection: inspection.id },
        });
        this.renderKey++;
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("REQUESTS.REQUEST_COMPLETED"),
        });
        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    changeSelectedStatus(
      status,
      assignedTo = null,
      creator = null,
      group = false
    ) {
      this.selectedInspectionStatus = status;
      this.selectedInspectionAssignedTo = assignedTo;
      this.selectedInspectionCreator = creator;
      this.selectedInspectionAssignedToGroup = group;
    },

    allInspections() {
      if (this.$currentUserCan(this.$permissions.PERM_VIEW_ANY_INSPECTIONS)) {
        this.changeSelectedStatus(null);
      } else if (
        this.$currentUserCan(
          this.$permissions.PERM_VIEW_ASSIGNED_TO_GROUP_INSPECTIONS
        )
      ) {
        this.changeSelectedStatus(null, this.$currentUser.id, null, true);
      }
    },

    canApproveInspection(inspection) {
      return (
        !!inspection &&
        !!inspection.vehicle &&
        inspection.status == INSPECTION_STATUS_DRAFT &&
        this.$currentUserCan(this.$permissions.PERM_APPROVE_INSPECTIONS)
      );
    },

    canStartInspection(inspection) {
      return (
        !!inspection &&
        inspection.status == INSPECTION_STATUS_ASSIGNED &&
        this.$currentUserCan(this.$permissions.PERM_START_INSPECTIONS)
      );
    },

    canTakeOverInspection(inspection) {
      return (
        !!inspection &&
        inspection.status == INSPECTION_STATUS_IN_PROGRESS &&
        this.$currentUserCan(this.$permissions.PERM_START_INSPECTIONS) &&
        this.me.id != inspection.startedBy?.id &&
        this.$currentUserCan(this.$permissions.PERM_TAKE_OVER_INSPECTIONS)
      );
    },

    canAssignInspection(inspection) {
      return (
        !!inspection &&
        this.$currentUserCan(this.$permissions.PERM_ASSIGN_INSPECTIONS) &&
        inspection.status == INSPECTION_STATUS_PENDING
      );
    },

    canCancelInspection(inspection) {
      return (
        !!inspection &&
        this.$currentUserCan(this.$permissions.PERM_CANCEL_INSPECTIONS) &&
        [
          INSPECTION_STATUS_DRAFT,
          INSPECTION_STATUS_PENDING,
          INSPECTION_STATUS_ASSIGNED,
        ].includes(inspection.status)
      );
    },

    canEditInspection(inspection) {
      return (
        !!inspection &&
        ![INSPECTION_STATUS_CANCELED, INSPECTION_STATUS_COMPLETED].includes(
          inspection.status
        ) &&
        (this.$currentUserCan(this.$permissions.PERM_EDIT_INSPECTIONS) ||
          this.$currentUserCan(this.$permissions.PERM_APPROVE_INSPECTIONS) ||
          this.$currentUserCan(this.$permissions.PERM_ASSIGN_INSPECTIONS))
      );
    },

    canViewInspection(inspection) {
      return (
        !!inspection &&
        ![INSPECTION_STATUS_DRAFT, INSPECTION_STATUS_PENDING].includes(
          inspection.status
        ) &&
        this.$currentUserCan(this.$permissions.PERM_VIEW_INSPECTIONS)
      );
    },

    canDeleteInspection(inspection) {
      return (
        !!inspection &&
        inspection.status == INSPECTION_STATUS_DRAFT &&
        this.$currentUserCan(this.$permissions.PERM_DELETE_INSPECTIONS)
      );
    },

    canCompleteRequest(inspection) {
      if (!inspection) {
        return false;
      }
      if (!inspection.request) {
        return false;
      }
      if (inspection.status != INSPECTION_STATUS_COMPLETED) {
        return false;
      }
      if (!inspection.request.id) {
        return false;
      }
      if (inspection.request.status !== REQUEST_STATUS_ASSIGNED) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss"></style>
